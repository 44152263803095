/**
 * Parametre 
 *  |- Modificatio des frais standars
 *  => Ajout de frais divers
 */
import React from 'react'
import './parametre.css'

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getCategory } from '../../services/residenceService'
import { putAllCategory } from "../../services/parametreService"

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const Parametre = () => {

    const  [clickTable , setClickTable] = useState(false)
    const  [category , setCategory] = useState(0)
    const  [load , setLoad] = useState(true)

    const handleChangeTable = (event) => {
        const [id, name] = event.target.name.split('+')
        const value = event.target.value
        const list  = [...category]
        list[id][name] = value
        setCategory(list)
    }

// [
//   {
//     "id": 1,
//     "denomination": "Chambre sanitaire",
//     "description": "sol carelé",
//     "montant_penalite_tranche_1": "0.1",
//     "montant_penalite_tranche_2": "0.2",
//     "montant_penalite_tranche_3": "0.3"
//   },
// ]

    useEffect(() => {
        setLoad(true)
        getCategory()
        .then(res => {
            setCategory(res)
            setLoad(false)
        })
    }, []);

    const handleSubmit = async (event) => {
        await putAllCategory(category)
    }

    return (
        <div id='parametre'>
            <div className='parametre__wrapper'>
                <div className='multiStepForm__title'>
                    Configuration des modalités
                </div>
                <div className='parametre__header__button'>
                    <Link to="frais">
                        <div className='locataires__ajout'>
                            <AddOutlinedIcon /><span>Ajouter un frais divers</span>
                        </div>
                    </Link>
                </div>
                <div className='parametre__body'>
                    <div className='parametre__body__wrapper'>
                        <div className='parametre__content_title'>Pénalités</div>
                        <div className='table'>
                            <div className='parametre__content_sub'>renseigner les prix supplémentaires</div>
                            <form onSubmit={handleSubmit}>
                                {
                                    load === true ? <div className='loading'>
                                        <div className="db-spinner"></div>
                                    </div> :
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>1 ere semaine</th>
                                                <th>2 e semaine</th>
                                                <th>3 e et 4e semaine</th>
                                            </tr>
                                        </thead>
                                        {
                                            category.map((data, i) => (
                                                <tbody key={i}>
                                                    <tr >
                                                        <td>{data['denomination']}</td>
                                                        <td>
                                                            <input
                                                                disabled={!clickTable}
                                                                type='text'
                                                                name={`${i}+montant_penalite_tranche_1`}
                                                                value={data['montant_penalite_tranche_1']}
                                                                onChange={handleChangeTable}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input 
                                                                disabled={!clickTable}
                                                                type='text'
                                                                name={`${i}+montant_penalite_tranche_2`}
                                                                value={data['montant_penalite_tranche_2']}
                                                                onChange={handleChangeTable}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input 
                                                                disabled={!clickTable}
                                                                type='text'
                                                                name={`${i}+montant_penalite_tranche_3`}
                                                                value={data['montant_penalite_tranche_3']}
                                                                onChange={handleChangeTable}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ))
                                        }
                                    </table>
                                }
                                <div className='parametre__content__but'>
                                    {clickTable === false ? 
                                        <div className='profil__body__form__button' onClick={() => setClickTable(!clickTable)}>Modifier</div> :
                                        <button className='profil__body__form__button' type="submit" onClick={(event) => {
                                            setClickTable(!clickTable)
                                            handleSubmit(event)
                                        }}>Valider</button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Parametre