/**
 * CARD POUR L'AFFICHAGE DES RESIDENCES
 */
import React from 'react'
import './residenceCard.css'

import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { Link } from 'react-router-dom';

const ResidenceCard = (props) => {

    return (
        <div id="residenceCard">
            <div className='residenceCard__wrapper'>
                <div className='residenceCard__img'>
                    <img 
                        src={props.img}
                        alt={props.title}
                    />
                </div>
                <div className='residenceCard__body'>
                    <div className='residenceCard__body--title'>{props.title}</div>
                    <div className='residenceCard__body--content'>{props.content}</div>
                    <div className='residenceCard__body--number'>{props.number} Chambres</div>
                    <div className='residenceCard__body--foot'>
                        <div className='residenceCard__body--address'>
                            <PlaceOutlinedIcon />
                            <span>{props.address}</span>
                        </div>
                        <Link to={`/residences/${props.id}`}>
                            <button className='locataires__ajout'>
                                Voir les détails
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResidenceCard