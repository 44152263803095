import API from "./Api"
import {getToken} from "./loginService"

/*

{
  "ids": "string",
  "montant": "string",
  "description": "string",
  "locations_ids": "string"
}

[
  {
    "id": 13,
    "date_depart": "2023-04-21",
    "date_fin": "2023-04-21",
    "status": "EN_COURS",
    "contrat": null,
    "signature": null,
    "prix_loc": "99999999.00",
    "mod_paiement": "MENSUEL",
    "prefs_paiement": "PREPAYE",
    "chambre": 15,
    "locataire": 25
  },
  {
    "id": 19,
    "date_depart": "2023-04-21",
    "date_fin": "2023-07-14",
    "status": "EN_COURS",
    "contrat": "http://3.142.172.6:8000/media/maquette.png",
    "signature": null,
    "prix_loc": "10000.00",
    "mod_paiement": "MENSUEL",
    "prefs_paiement": "PREPAYE",
    "chambre": 16,
    "locataire": 25
  }
]

/location/location_infos/by_all_without_contrainte/?chambre=16

*/

export const createFrais = async (state, data) => {
    const idToken = getToken()
    let ids = ""
    let locations_ids = ""

    for(let i = 0; i < data.length; i++) {
        const location = await API.get(`/location/location_infos/by_all_without_contrainte/?chambre=${data[i]['id']}`,{
                                    headers : {
                                        "Authorization" : 'Bearer ' + idToken
                                    }
                                })
                            .then(res => res.data)
                            .catch(_ => "error")

        if(location === "error") {
            return []
        }

        locations_ids += (i > 0 ? "," : "") + location[0]["id"].toString()
        ids += (i > 0 ? "," : "") + location[0]['locataire'].toString()
    }

    /*
    
    {
        "ids": "string",
        "montant": "string",
        "description": "string",
        "locations_ids": "string"
    }
    
    */

    state["ids"] = ids
    state["locations_ids"] = locations_ids

    const inter = await API.post(`/location/assign_frais_divers`,
                            state, {
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    return inter
}