import API from "./Api"
import { getAdminId } from "./userService"
import {getToken} from "./loginService"

/*

{
    "id": 1,
    "nom_residence": "Assouka eco House",
    "description": "Yo c'est pour les boss",
    "ville": "Lounge",
    "adresse": "string",
    "arrondissement": "string",
    "quartier": "string",
    "departement": "string",
    "pays": "string",
    "coordonne_geo": "-2.00005;6.2323224",
    "image": null,
    "jour_penalite": 5,
    "proprietaire": 1
},

{
    "img": img3,
    "title": "Résidence le bonheur",
    "content": "La Résidence Les Jardins est un complexe résidentiel de haute qualité situé dans un quartier chic de la ville. Il propose des appartements spacieux et modernes avec des équipements tels que piscine, gymnase, salon de coiffure, etc.",
    "number": 190,
    "address": "Zogbadjé, Godomey, Abomey-calavi, Atlantique, Bénin"
}

{
    "id": 1,
    "designation": "string",
    "description": "string",
    "prix_moyen": "90000.00",
    "autres": "string",
    "etat_chambre": "string",
    "statut": "LIBRE",
    "residence": 1,
    "category": 1
},

*/

// Ce service est pour le proprietaire
export const getAllResidence = async () => {

    const response = await API.get('/logistiques/Résidence/')
                        .then(res => res.data)
                        .catch(_ => "error")

    if(response === "error") {
        return []
    }

    const inter = await API.get('/logistiques/Chambre/')
                        .then(res => res.data)
                        .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    for(let i = 0; i < response.length; i++) {
        let sum = 0
        for(let j = 0; j < inter.length; j++) {
            if(response[i]["id"] == inter[j]['residence']) {
                sum +=  1
            }
        }
        response[i]['number'] = sum
    }

    return response
}

export const getResidence = async () => {
    const idToken = getToken()
    const response = await API.get('/logistiques/my_residences', 
                        {
                            headers : {
                                "Authorization" : 'Bearer ' + idToken
                            }
                        })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(response === "error") {
        return []
    }

    const inter = await API.get('/logistiques/Chambre/')
                        .then(res => res.data)
                        .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    for(let i = 0; i < response.length; i++) {
        let sum = 0
        for(let j = 0; j < inter.length; j++) {
            if(response[i]["id"] == inter[j]['residence']) {
                sum +=  1
            }
        }
        response[i]['number'] = sum
    }

    return response
}

export const getChambre = async (id) => {

    let res = []

    const inter = await API.get('/logistiques/Chambre/')
                        .then(res => res.data)
                        .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    for(let i = 0; i < inter.length; i++) {
        if(inter[i]["residence"] == id) {
            res.push(inter[i])
        }
    }

    return res
}

export const getOneChambre  = async (id) => {

    const inter = await API.get(`/logistiques/Chambre/${id}`)
                        .then(res => res.data)
                        .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    return inter
}

export const getInfos = async (id) => {

    const response = await API.get(`/logistiques/Résidence/${id}/`)
                            .then(res => res.data)
                            .catch(_ => "error")

    if(response === "error") {
        return []
    }

    return response
}

export const saveChambre = async (credentials, id) => {

    credentials["statut"] = "LIBRE"
    credentials["residence"] = id

    const res = await API.post('/logistiques/Chambre/', credentials)
                        .then(res => res.data)
                        .catch(_ => "error")

    if(res === "error") {
        return []
    }

    return res
    
}

export const updateChambre = async (credentials, id) => {

    const res = await API.put(`/logistiques/Chambre/${credentials["id"]}/`, {
        "designation": credentials["designation"],
        "description": credentials["description"],
        "prix_moyen": credentials["prix_moyen"],
        "autres": credentials["autres"],
        "etat_chambre": credentials["etat_chambre"],
        "statut": credentials["statut"],
        "residence": credentials["residence"],
        "category": credentials["category"]

    })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(res === "error") {
        return []
    }

    return res
}

export const getCategory = async () => {

    const response = await API.get('/logistiques/Category/')
                        .then(res => res.data)
                        .catch(_ => "error")

    if(response === "error") {
        return []
    }

    return response
}

/*

{
  "nom_residence": "string2",
  "description": "string",
  "ville": "string",
  "adresse": "string",
  "arrondissement": "string",
  "quartier": "string",
  "departement": "string",
  "pays": "string",
  "coordonne_geo": "string"
}

*/

export const saveResidence = async (credentials) => {

    credentials["coordonne_geo"] = ""
    credentials["proprietaire"] = await getAdminId()

    const res = await API.post('/logistiques/Résidence/', credentials, {
                            headers: {
                            'content-type': 'multipart/form-data',
                            }
                        })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(res === "error") {
        return []
    }

    return res
}

export const getAllChambre = async () => {

    const inter = await API.get('/logistiques/Chambre/')
                        .then(res => res.data)
                        .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    return inter
}

export const updateResidence = async (credentials) => {

    const id = credentials['id']

    delete credentials.image;

    const res = await API.put(`/logistiques/Résidence/${id}/`, credentials, {
                            headers: {
                            'content-type': 'multipart/form-data',
                            }
                        })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(res === "error") {
        return []
    }

    return res
}