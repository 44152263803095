/**
 *  Liste de residence
 */
import React from 'react'
import './residenceList.css'
import {getResidence} from "../../services/residenceService"

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import ResidenceCard from '../../components/residenceCard/ResidenceCard';

import img1 from "../../assets/img1.png"

const ResidenceList = () => {
    const [data, setData] = useState(0)

    useEffect(() => {
        getResidence().then(
            (res) =>{
                setData(res)
            }
        )
    }, [])

    //Image not provide

  return  (
    <div id="residence">
        <div className='residence__wrapper'>
            <div className='locataires__header'>
                <div className='locataires__title'>
                    Liste des résidences
                </div>
                <Link to="/residences/ajouter">
                  <div className='locataires__ajout'>
                    <AddOutlinedIcon /><span>Ajouter une résidence</span>
                  </div>
                </Link>
            </div>
            <div className='residence__body'>
                <div className='residence__body__wrapper'>
                    {data === 0 ? <div className='loading'>
                                    <div className="db-spinner"></div>
                                </div> : data.map((data, index) => (
                                            <ResidenceCard
                                                img = {data.image === null ? img1 : data.image} 
                                                title = {data.nom_residence}
                                                content = {data.description} 
                                                number = {data.number}
                                                id={data.id}
                                                address = {data['adresse'] + ", " + data['quartier'] + ", " + data['arrondissement'] + ", " + data['departement'] + ", " + data['pays']}
                                                key={index}
                                            />
                    ))}
                </div>
            </div>
        </div>
    </div>
  )

}

export default ResidenceList