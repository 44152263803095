import API from "./Api"
import { getUser } from "./userService"

export const Auth = async (credentials) => {

  /*
   * Requete post
   */

  const response = await API.post(`/auth/login/`, credentials)
                          .then(async (res) => {

                            localStorage.setItem('NiI.sInR5-cCI-6I?kpX', res.data.token.access);

                            const type = await getUser()
                            if(type['type'] === "LOCATAIRE") {
                              localStorage.removeItem('NiI.sInR5-cCI-6I?kpX', res.data.token.access);
                              return "error"
                            }

                            return res.data.msg
                          })
                          .catch(() => "error")

  return response 
}

export const checkConnect = async () => {
  return localStorage.getItem('NiI.sInR5-cCI-6I?kpX') === null ? true : false
}

export const getToken = () => {
  const val = localStorage.getItem('NiI.sInR5-cCI-6I?kpX')
  return val
}

export const sendMail = async (credentials) => {

  const response = await API.post(`/auth/send-reset-password-mail/`, credentials)
                          .then(async (res) => {
                            return res.data.msg
                          })
                          .catch(() => "error")

  return response 
}

export const forgot = async (credentials, params) => {
  
  const response = await API.post(`/auth/reset-password/${params.uid}/${params.token}/`, credentials)
                          .then(async (res) => {
                            return res.data
                          })
                          .catch(() => "error")

  return response 
}