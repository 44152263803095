/**
 * CARD POUR L'ETAT FINANCIER ACTUEL
 */
import React from 'react'
import "./primaryCard.css"

import arrow from "../../assets/arrow.svg"
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';


const PrimaryCard = ({title, amount, percentage, pie}) => {

    const percent = percentage === "NaN" ? 0 : percentage

    const dataColor = {
        "Loyer": "loyer",
        "Pénalité": "penalite",
        "Frais divers": "frais",
        "Prépayer": "prepayer"
    }

    const pieColor = {
        "Loyer": "rgba(79, 209, 139, 1)",
        "Pénalité": "rgba(220, 53, 69, 1)",
        "Frais divers": "rgba(157, 155, 244, 1)",
    }

    ChartJS.register(ArcElement, Tooltip, Legend);

    const data = {
        datasets: [
            {
                data: [100-percent, percent],
                backgroundColor: [
                    'rgba(239, 244, 253, 1)',
                    pieColor[title],
                ],
                borderColor: [
                    'rgba(239, 244, 253, 1)',
                    pieColor[title],
                ],
                borderWidth: 1,
            },
        ],
    };


    return (
        <div id='card'>
            <div className={`card__title ${dataColor[title]}`}>{title}</div>
            <div className='card__contain'>
                <div className='card__numbers'>
                    <div className='card__amount'>{amount}F</div>
                    {pie && <div className='card__percentage'>
                        <img 
                            src={arrow}
                            alt="arrow"
                        />
                        {percent}%
                    </div>}
                </div>
                <div className='pie'>
                    <Doughnut data={data}/>
                </div>
            </div>
            {/* <Link to="/dashboard">
                <div className='card__details'>
                    Voir les détails
                </div>
            </Link> */}
        </div>
    )
}

export default PrimaryCard