/**
 * LOGIN
 */
import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './login.css'
import {
    Auth
} from "../../services/loginService"

import logo from "../../assets/logo.svg"
import desc from "../../assets/hero.svg"

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { Link } from 'react-router-dom'

import {getUser} from "../../services/userService"

const Login = () => {

    const [show, setShow] = useState(true)
    const [admin, setAdmin] = useState(false)
    const [state, setState] = useState({})
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setState({...state, [name] : value});
    }

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        setError(false)
        // Connexion
        Auth(state)
            .then((response) => {
            setLoading(false)
            if(response === "Login Sucess") {
                getUser()
                .then(res => {
                    if (res.length !== 0) {
                        if("type" === "LOCATAIRE") {
                            setAdmin(true)
                        } else {
                            navigate("/dashboard")
                        }
                    }
                    setError(false)
                }).catch((err) => {
                    window.location.reload()
                })
            } else {
                setError(true)
            }
        })
    }

    return (
        <div id='login'>
            <div className='introduction'>
                <div className='introduction__wrapper'>
                    <div className='introduction__logo'>
                        <img 
                            src={logo}
                            alt='logo'
                        />
                    </div>
                    <div className='introduction__img'>
                        <img 
                            src={desc}
                            alt='feature description'
                        />
                    </div>
                    <div className='introduction__content'>
                        <div className='introduction__title'>
                            Bienvenue admin !
                        </div>
                        <div className='introduction__desc'>
                            Commencer par gérer votre bien immobilier <br /> Suivre votre bail de maniere éfficace 
                        </div>
                    </div>
                </div>
            </div>
            <div className='signin'>
                <div className='signin__wrapper'>
                    <div className='introduction__logo logo-none'>
                        <img 
                            src={logo}
                            alt='logo'
                        />
                    </div>
                    <div className='signin__head'>
                        <div className='signin__title'>
                            Bienvenue <span className='blue'>Bailleur !</span>
                        </div>
                        <div className='introduction__desc'>Commençons à simplifier votre propriété locative</div>
                    </div>
                    <div className='signin__form'>
                        <form onSubmit={handleSubmit}>
                            <div className='signin__box'>
                                <div className='signin__icon'>
                                    <EmailOutlinedIcon className='blue'/>
                                </div>
                                <input 
                                    className='introduction__desc input-1'
                                    type='mail'
                                    name='email'
                                    placeholder='exemple@gmail.com'
                                    value={state.email || ""}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className='signin__box'>
                                <div className='signin__icon'>
                                    <HttpsOutlinedIcon className='blue'/>
                                </div>
                                <input
                                    className='introduction__desc input-2'
                                    type={show === true ? "password" : "text"}
                                    name='password'
                                    value={state.password || ""}
                                    onChange={handleChange}
                                    required
                                />
                                <div className='signin__icon--show' onClick={() => setShow(!show)}>
                                    {show === true ? <VisibilityOutlinedIcon className='blue'/> : <VisibilityOffOutlinedIcon className='blue'/>}
                                </div>
                            </div>
                            <div className='signin__forget'>
                                <Link to={"/forgot"}>
                                    <div className='blue'>mot de passe oublié ?</div>
                                </Link>
                            </div>
                            {error &&   <div className='login-error introduction__desc'>
                                            {admin === false ? 'informations invalides' : 'Connectez-vous en tant que admin'}
                                        </div>
                            }

                            {
                                loading === true ? <div className='loading'>
                                                        <div className="db-spinner"></div>
                                                    </div> : <button className='signin__submit' type='submit'>
                                                                Se connecter
                                                            </button>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login