import API from "./Api"
import { getLocataireId } from "./locataireService"
import {getToken} from "./loginService"
import { getOneChambre } from "./residenceService"

// {
//     "id": 1,plaintes
//     "is_active": true,
//     "piece_identite": null,
//     "status": "NOUVEAU",
//     "photo": "http://3.142.172.6:8000/media/default.png",
//     "user": {
//       "id": 1,
//       "password": "pbkdf2_sha256$390000$rqsLljZVqyzRIkUMSHrVb0$Lo2AYeeBRrOtFErOU3BN2df4QlUOUBf8aNIGoatEYiM=",
//       "last_login": null,
//       "is_superuser": false,
//       "email": "hernandezdecos96@gmail.com",
//       "nom": "DOE",
//       "prenom": "John",
//       "sexe": "NON_DEFINI",
//       "type": "LOCATAIRE",
//       "picture": "http://3.142.172.6:8000/media/default.png",
//       "telephone": "",
//       "birth_date": "2023-03-27",
//       "adress": "",
//       "is_admin": false,
//       "date_created_at": "2023-03-27T18:08:52.652399Z",
//       "date_updated_at": "2023-03-27T18:08:52.652429Z",
//       "groups": [],
//       "user_permissions": []
//     }
// }

// {
//     "email": "user@example.com",
//     "nom": "string",
//     "prenom": "string",
//     "type": "LOCATAIRE",
//     "password": "string",
//     "password2": "string",
//     "adresse": "string",
//     "profession": "string",
//     "sexe": "HOMME",
//     "birth_date": "2023-04-15",
//     "description": "string",
//     "telephone": "string"
// }

export const createLocataire = async (credentials) => {
    // console.log("STATE", credentials)
    const idToken = getToken()
    const user = {
        "email": credentials["email"],
        "nom": credentials["nom"],
        "prenom": credentials["prenom"],
        "type": credentials["type"],
        "password": "mayton",
        "password2": "mayton",
        "adresse": credentials["adresse"],
        "profession": credentials["profession"],
        "sexe": credentials["sexe"],
        "birth_date": credentials["birth_date"],
        "description": credentials["description"],
        "telephone": credentials["telephone"]
    }

    /*
    {
        "email": "bbb@example.com",
        "nom": "string",
        "prenom": "string",
        "type": "LOCATAIRE",
        "password": "string",
        "password2": "string",
        "adresse": "string",
        "profession": "string",
        "sexe": "HOMME",
        "birth_date": "2023-04-15",
        "description": "string",
        "telephone": "string"
    }
    */

    const inter = await API.post(`/auth/register/`,
                            user,
                            {
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                        .then(res => res.data)
                        .catch(err => err)

    if(inter === "error") {
        // {"errors": {"email": ["user with this email address already exists."]}}
        return "Email existant"
    }

    if (credentials["type"] !== "LOCATAIRE") {
        return inter
    }

    const idLocataire = await getLocataireId(credentials['email'])

    // console.log("idLocataire", idLocataire)

    // Choix du prix
    const montant = await getOneChambre(credentials['chambre'])
    
    // piece, contrat
    // {
    //     "date_depart": "2023-04-12",
    //     "date_fin": "2023-04-12",
    //     "status": "EN_COURS", #
    //     "prix_loc": "string",
    //     "mod_paiement": "MENSUEL", #
    //     "prefs_paiement": "PREPAYE", #
    //     "chambre": 0,
    //     "locataire": 0
    // }

    const location = {
        "date_depart": credentials['date_depart'],
        "date_fin": credentials['date_fin'],
        "status": "ATTENTE", 
        "prix_loc": montant['prix_moyen'],
        "mod_paiement": "MENSUEL", 
        "prefs_paiement": "PREPAYE", 
        "chambre": credentials['chambre'],
        "locataire": idLocataire
    }

    const resLocation = await API.post(`/location/create_location/`,
                            location,
                            {
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(resLocation === "error") {
        return 
    }

    // id
    // piece_identite

    const addPiece = await API.post(`/auth/add_piece_identity_by_admin`,
                            {
                                id : idLocataire,
                                piece_identite : credentials['piece']
                            },
                            {
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken,
                                    'content-type' : 'multipart/form-data',
                                    'Accept': 'application/json'
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(addPiece === "error") {
        return 
    }

    const addContrat = await API.put(`/location/add_contrat_for_locataire/${resLocation['id']}`,
                            {
                                contrat: credentials['contrat']
                            },
                            {
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken,
                                    'content-type' : 'multipart/form-data',
                                    'Accept': 'application/json'
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(addContrat === "error") {
        return 
    }

    return addContrat
}

export const suspendre = async (id) => {
    const idToken = getToken()

    const inter = await API.post(`/location/desactive_location?id_location=${id}`,
                            {
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                        .then(res => res.data)
                        .catch(err => err)

    if(inter === "error") {
        return "error"
    }

    return inter
}

export const updateContrat = async (id, data) => {
    const idToken = getToken()

    const inter = await API.put(`/location/add_contrat_for_locataire/${id}`,
                            {
                                "contrat": data
                            },
                            {
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken,
                                    'content-type' : 'multipart/form-data',
                                    'Accept': 'application/json'
                                }
                            })
                        .then(res => res.data)
                        .catch(err => err)

    if(inter === "error") {
        return "error"
    }

    return inter
}

export const assigneLocataire = async (credentials) => {
    const idToken = getToken()
    const idLocataire = credentials['id']

    // Choix du prix
    const montant = await getOneChambre(credentials['chambre'])
    
    // piece, contrat
    // {
    //     "date_depart": "2023-04-12",
    //     "date_fin": "2023-04-12",
    //     "status": "EN_COURS", #
    //     "prix_loc": "string",
    //     "mod_paiement": "MENSUEL", #
    //     "prefs_paiement": "PREPAYE", #
    //     "chambre": 0,
    //     "locataire": 0
    // }

    const location = {
        "date_depart": credentials['date_depart'],
        "date_fin": credentials['date_fin'],
        "status": "ATTENTE", 
        "prix_loc": montant['prix_moyen'],
        "mod_paiement": "MENSUEL", 
        "prefs_paiement": "PREPAYE", 
        "chambre": credentials['chambre'],
        "locataire": idLocataire
    }

    const resLocation = await API.post(`/location/create_location/`,
                            location,
                            {
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(resLocation === "error") {
        return 
    }

    // id
    // piece_identite

    const addPiece = await API.post(`/auth/add_piece_identity_by_admin`,
                            {
                                id : idLocataire,
                                piece_identite : credentials['piece']
                            },
                            {
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken,
                                    'content-type' : 'multipart/form-data',
                                    'Accept': 'application/json'
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(addPiece === "error") {
        return 
    }

    const addContrat = await API.put(`/location/add_contrat_for_locataire/${resLocation['id']}`,
                            {
                                contrat: credentials['contrat']
                            },
                            {
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken,
                                    'content-type' : 'multipart/form-data',
                                    'Accept': 'application/json'
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(addContrat === "error") {
        return 
    }

    return addContrat
}