/**
 * Liste des locataires
 *  |- Liste
 *  |- Modification de locataire
 */
import React from 'react'
import './locataireList.css'

import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import DataTable from 'react-data-table-component';
import Modal from 'react-modal';

import Img from '../../utils/Img';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';

import ThemeContext from '../../utils/themeContext';
import { getAdminLocataire, getLocataireList, getOneLocataire, updateLocataire } from '../../services/locataireService';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const LocataireList = () => {

  const [locataires, setLocataires] = useState(0)
  const [locataire, setLocataire] = useState(0)
  const [modalIsOpen, setIsOpen] = useState(false)
  Modal.setAppElement(document.getElementById('root'));

  useEffect(() => {
    // getLocataire pour propriétaire
    getAdminLocataire()
    .then(res => {
      getLocataireList(res)
      .then(list => {
        setLocataires(list)
      })
    })
  }, [modalIsOpen])

    const columns = [
      {
        name: 'Image',
        selector: row => <Link to={`${row.id}`} className='datatable__img'><Img src={row.img} alt={"user" + row.id} /></Link>,
      },
      {
          name: 'Nom et prenoms',
          selector: row => <Link to={`${row.id}`} className='user__infos'>{row.title}</Link>,
          sortable: true,
      },
      {
        name: 'Date de location',
        selector: row => <Link to={`${row.id}`} className='user__infos'>{row.year}</Link>,
      },  
      {
        name: 'Status',
        selector: (row) => {
          if(row.status === "NOUVEAU") {
            return <Link to={`${row.id}`} className='user__infos'><span className='green'>{row.status}</span></Link>
          } else {
            return <Link to={`${row.id}`} className='user__infos'><span className='blue'>{row.status}</span></Link>
          }
        },
      },
      {
        name: "Modifer",
        selector: (row) => (<button 
          className='multiStepForm__but'
          onClick={async e => {
            await getOneLocataire(row.id)
                    .then(res => setLocataire({...res.user, 'idUp': row.id}))
            setIsOpen(true)
          }}
        ><EditIcon /></button>),
      }
    ];

    const handleChange = async (event) => {
      const name = event.target.name;
      let value
      if(name === "piece") {
        value = await event.target.files[0]
      } else {
        value = event.target.value;
      }
      setLocataire({...locataire, [name] : value});
  }

  const handleSubmit = async (event) => {
      event.preventDefault();
      await updateLocataire(locataire)
              .then(res => {
                if(res !== "error") {
                  closeModal()
                }
              })
  }

    const closeModal = () => {
      setIsOpen(false)
    }

    return (
      <ThemeContext.Consumer>
          {({theme, _}) => (
            <div>
              <div id='locataires'>
                <div className='locataires__header'>
                  <Link to="/locataires/assigner">
                    <div className='locataires__ajout'>
                      <AddOutlinedIcon /><span>Assigner une chambre</span>
                    </div>
                  </Link>
                  <Link to="/locataires/ajouter">
                    <div className='locataires__ajout'>
                      <AddOutlinedIcon /><span>Ajouter un utilisateur</span>
                    </div>
                  </Link>
                </div>
                <div className='locataires__header'>
                  <div className='locataires__title'>
                    Liste des locataires actuels
                  </div>
                </div>
                <div className='locataires__datatable'>
                  <div className='locataires__datatable__wrapper'>
                    {locataires === 0 ? <div className='loading'>
                                          <div className="db-spinner"></div>
                                      </div> :
                                      <DataTable
                                        columns={columns}
                                        data={locataires}
                                        pagination
                                        theme={ theme === "dark" ? "default" : "dark" }
                                      />
                    }
                  </div>
                </div>
              </div>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                {locataire === 0  ? <div className='loading'>
                          <div className="db-spinner"></div>
                      </div> : <form className="multiStepForm__form" style={{padding: '20px'}} onSubmit={handleSubmit}>
                                    <div className='form__wrapper'>
                                        <div className='multiStepForm__header'>
                                            <div className='multiStepForm__title'>Modification d'un locataire</div>
                                        </div>
                                        <div className='form__flex'>
                                            <div className='form__box'>
                                                <label>Nom</label>
                                                <input 
                                                    type='text'
                                                    name='nom'
                                                    placeholder='AH'
                                                    value={locataire.nom || ""}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className='form__box'>
                                                <label>Prénom</label>
                                                <input 
                                                    type='text'
                                                    name='prenom'
                                                    value={locataire.prenom || ""}
                                                    placeholder='Gil'
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='form__flex'>
                                            <div className='form__box'>
                                                <label>Sexe</label>
                                                <div id='select'>
                                                    <select onChange={handleChange} name="sexe" defaultValue={locataire.sexe}>
                                                        <option value="HOMME">masculin</option>
                                                        <option value="FEMME">féminin</option>
                                                        <option value="NON_DEFINI">autres</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='form__box'>
                                                <label>Email</label>
                                                <input 
                                                    type='mail'
                                                    name='email'
                                                    value={locataire.email || ""}
                                                    placeholder='exemple@gmail.com'
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='form__flex'>
                                            <div className='form__box'>
                                                <label>Téléphone</label>
                                                <input 
                                                    type='number'
                                                    name='telephone'
                                                    value={locataire.telephone || ""}
                                                    placeholder='+22921658741'
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className='form__box'>
                                                <label>Date de naissance</label>
                                                <input 
                                                    type='date'
                                                    name='birth_date'
                                                    value={locataire.birth_date || ""}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='form__flex'>
                                            <div className='form__box__file'>
                                                <label>Piece d'identité</label>
                                                <label className='form__file' htmlFor="piece">
                                                    <div className='form__file__but'>
                                                        <DriveFolderUploadOutlinedIcon/>
                                                    </div>
                                                </label>
                                                <input 
                                                    type='file'
                                                    id='piece'
                                                    name='piece'
                                                    onChange={handleChange}
                                                    accept=".jpg, .jpeg, .png, .pdf"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='multiStepForm__footer'>
                                      <div></div>
                                      <button className='multiStepForm__but' type='submit' style={{marginTop: "20px"}}>
                                          <span>Modifier</span>
                                      </button>
                                    </div>
                                </form>
                }
              </Modal>
            </div>
          )}
      </ThemeContext.Consumer>
    )
}

export default LocataireList