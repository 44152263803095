/**
 * AJOUT DE CHAMBRE 
 * Ajout de locataire
 * Assignation de chambre (Creation de location)
 */
import React from 'react'
import "./multiStepForm.css"

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';

import {getResidence, getAllChambre } from "../../services/residenceService"
import {createLocataire} from "../../services/locationService"

const MultiStepForm = () => {

    const [state, setState] = useState({
        "type" : "LOCATAIRE"
    })
    const [select, setSelect] = useState("la glore")
    const [dataResidence, setDataResidence] = useState(0)
    const [dataChambre, setDataChambre] = useState(0)
    const [check, setCheck] = useState(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    // {
    //     "id": 1,
    //     "designation": "L033-A22",
    //     "description": "Yo c'est ça",
    //     "prix_moyen": "50000.00",
    //     "autres": "string",
    //     "etat_chambre": "Très bon",
    //     "statut": "OCCUPE",
    //     "residence": 1,
    //     "category": 1
    // }

    useEffect(() => {
        getAllChambre().then(
            (chambre) => {
                getResidence().then(
                    (res) => {
                        let dataTest = []
                        let setForChambre = {}
                        let bool = false
        
                        // Mise en place des dictionnaire {'residence': [chambre, ...]}
                        for(let i = 0; i < res.length; i++) {
                            dataTest.push([res[i]['nom_residence']])
                            setForChambre[res[i]['nom_residence']] = [["Séléctionnez une chambre", ""]]
                            for(let j = 0; j < chambre.length; j++) {
                                if(chambre[j]['residence'] == res[i]['id'] && chambre[j]["statut"] != "OCCUPE") {
                                    bool = true
                                    setForChambre[res[i]['nom_residence']].push([chambre[j]['designation'], chambre[j]['id']])
                                }
                            }
                        }

                        if(!bool) {
                            setCheck(true)
                        }
        
                        setDataResidence(dataTest)
                        setDataChambre(setForChambre)
                        // console.log(setForChambre)
                        setSelect(dataTest[0][0])
                    }
                )
            }
        )
    }, [])

    const handleChange = async (event) => {
        const name = event.target.name;
        let value;
        if(name === "contrat" || name === "piece") {
            value = await event.target.files[0]
        } else {
            if(name === "chambre") {
                // console.log(event.target.value)
            }
            value = event.target.value;
        }
        setState({...state, [name] : value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        await createLocataire(state)
            .then(res => {
                if(res !== "error") {
                    navigate('/locataires')
                }
            })
        setLoading(false)
        navigate('/locataires')
    }

    const [index, setIndex] = useState(1)

    const precedent = () => {
        if(index > 1) {
            setIndex(index - 1)
        }
    }

    const suivant = () => {
        if(index < 3) {
            setIndex(index + 1)
        }
    }

    const handleSelect = (event) => {
        if(event.target.value != select) {
            setSelect(event.target.value)
        }
    }

    return ( dataResidence === 0 || dataChambre === 0 || loading === true? <div className='loading'>
                                <div className="db-spinner"></div>
                            </div> : check === true ? <div className='multiStepForm__header'>
                            <div className='multiStepForm__title red'>Pas de chambre libres</div>
                        </div> : (<form onSubmit={handleSubmit}>
                <div id='multiStepForm'>
                    <div className='multiStepForm__wrapper'>
                        <div className='multiStepForm__header'>
                            <div className='multiStepForm__title'>Création  d’un utilisateur</div>
                        </div>
                        <div className='multiStepForm__body'>
                            <div className='multiStepForm__body__wrapper'>
                                <div className='multiStepForm__progress'>
                                    <ProgressBar
                                        percent={(index - 1) * 50 }
                                        filledBackground="#f7f7f7, #306FEB"
                                    >
                                        <Step transition="scale">
                                        {({ accomplished }) => (
                                            <div className={`step ${accomplished ? "completed" : ""}`}>1</div>
                                        )}
                                        </Step>
                                        <Step transition="scale">
                                        {({ accomplished }) => (
                                            <div className={`step ${accomplished ? "completed" : ""}`}>2</div>
                                        )}
                                        </Step>
                                        <Step transition="scale">
                                        {({ accomplished }) => (
                                            <div className={`step ${accomplished ? "completed" : ""}`}>3</div>
                                        )}
                                        </Step>
                                    </ProgressBar>
                                </div>
                                <div className={`multiStepForm__form ${index === 1 ? "" : "none"}`}>
                                    <div className='form__wrapper'>
                                        <div className='form__header'>
                                            Informations personnelles
                                        </div>
                                        <div className='form__flex'>
                                            <div className='form__box'>
                                                <label>Nom</label>
                                                <input 
                                                    type='text'
                                                    name='nom'
                                                    placeholder='AH'
                                                    value={state.nom || ""}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className='form__box'>
                                                <label>Prénom</label>
                                                <input 
                                                    type='text'
                                                    name='prenom'
                                                    value={state.prenom || ""}
                                                    placeholder='Gil'
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='form__flex'>
                                            <div className='form__box'>
                                                <label>Sexe</label>
                                                <div id='select'>
                                                    <select onChange={handleChange} name="sexe">
                                                        <option value="">Sélectionnez un type</option>
                                                        <option value="HOMME">masculin</option>
                                                        <option value="FEMME">féminin</option>
                                                        <option value="NON_DEFINI">autres</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='form__box'>
                                                <label>Email</label>
                                                <input 
                                                    type='mail'
                                                    name='email'
                                                    value={state.email || ""}
                                                    placeholder='exemple@gmail.com'
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='form__flex'>
                                            <div className='form__box'>
                                                <label>Téléphone</label>
                                                <input 
                                                    type='number'
                                                    name='telephone'
                                                    value={state.telephone || ""}
                                                    placeholder='+22921658741'
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className='form__box'>
                                                <label>Date de naissance</label>
                                                <input 
                                                    type='date'
                                                    name='birth_date'
                                                    value={state.birth_date || ""}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`multiStepForm__form ${index === 2 ? "" : "none"}`}>
                                    <div className='form__wrapper'>
                                        <div className='form__header'>
                                            Informations supllémentaires
                                        </div>
                                        <div className='form__flex'>
                                            <div className='form__box'>
                                                <label>Description</label>
                                                <textarea 
                                                    type='text'
                                                    name='description'
                                                    onChange={handleChange}
                                                    value={state.description || ""}
                                                    required
                                                >
                                                </textarea>
                                            </div>
                                            <div className='form__box__second'>
                                                <div className='form__flex__second'>
                                                    <div className='form__box'>
                                                        <label>Adresse</label>
                                                        <input 
                                                            type='text'
                                                            name='adresse'
                                                            value={state.adresse || ""}
                                                            placeholder='Cotonou, Rue 450'
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className='form__box'>
                                                        <label>Profession</label>
                                                        <input 
                                                            type='text'
                                                            name='profession'
                                                            value={state.profession || ""}
                                                            placeholder='Developpeur'
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form__flex'>
                                            <div className='form__box'>
                                                <label>Type</label>
                                                <div id='select'>
                                                    <select onChange={handleChange} name="type">
                                                        <option value="">Sélectionnez un type</option>
                                                        <option value="LOCATAIRE">locataire</option>
                                                        <option value="REPRESENTANT">gestionnaire</option>
                                                        {/* <option value="SUPERADMIN">proprietaire</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { state.type === "LOCATAIRE" && <div className={`multiStepForm__form ${index === 3 ? "" : "none"}`}>
                                    <div className='form__wrapper'>
                                        <div className='form__header'>
                                            Informations de location
                                        </div>
                                        <div className='form__flex'>
                                            <div className='form__box'>
                                                <label>Résidence <span className='red'> *cliquer pour choisir</span></label>
                                                <div id='select'>
                                                    <select 
                                                        onChange={(e) => {
                                                                handleChange(e)
                                                                handleSelect(e)
                                                            }
                                                            
                                                        } 
                                                        required
                                                        name="residence"
                                                    >
                                                        {dataResidence.map((data, i) =>
                                                            <option value={data} key={`${data} ${i}`} >{data}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='form__box'>
                                                <label>Chambre <span className='red'> *cliquer pour choisir</span></label>
                                                <div id='select'>
                                                    <select onChange={handleChange} name="chambre" required>
                                                        {typeof(dataChambre[select]) !== "undefined" && dataChambre[select].map((data, i) =>
                                                            <option value={data[1]} key={`${select} ${i}`}>{data[0]}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form__flex'>
                                            <div className='form__box'>
                                                <label>Date de départ</label>
                                                <input 
                                                    type='date'
                                                    name='date_depart'
                                                    value={state.date_depart || ""}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className='form__box'>
                                                <label>Date de fin</label>
                                                <input 
                                                    type='date'
                                                    name='date_fin'
                                                    value={state.date_fin || ""}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='form__flex__file'>
                                            <div className='form__box__file'>
                                                <label>Piece</label>
                                                <label className='form__file' htmlFor="piece">
                                                    <div className='form__file__but'>
                                                        <DriveFolderUploadOutlinedIcon/>
                                                    </div>
                                                </label>
                                                <input 
                                                    type='file'
                                                    id='piece'
                                                    name='piece'
                                                    onChange={handleChange}
                                                    accept=".jpg, .jpeg, .png, .pdf"
                                                    required
                                                />
                                            </div>
                                            <div className='form__box__file'>
                                                <label>Contrat</label>
                                                <label className='form__file' htmlFor="contrat">
                                                    <div className='form__file__but'>
                                                        <DriveFolderUploadOutlinedIcon/>
                                                    </div>
                                                </label>
                                                <input 
                                                    type='file'
                                                    id="contrat"
                                                    name='contrat'
                                                    onChange={handleChange}
                                                    accept=".jpg, .jpeg, .png, .pdf"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className='multiStepForm__footer'>
                            <div disabled={index===1} onClick={precedent} className='multiStepForm__but'>
                                <ArrowBackOutlinedIcon /> <span>Précedent</span>
                            </div>
                            {index === 3 ?
                                <button className='multiStepForm__but' type='submit'>
                                    <span>Soumettre</span>
                                </button> : <div className='multiStepForm__but' onClick={suivant}>
                                                <span>Suivant</span><ArrowForwardOutlinedIcon /> 
                                            </div>
                            }
                        </div>
                    </div>
                </div>
            </form>)
    )
}

export default MultiStepForm