/**
 * MOT DE PASSE OUBLIÉ
 */
import React from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, AlertTitle } from '@mui/material';

import "./forgot.css"

import '../login/Login'
import {
    forgot
} from "../../services/loginService"


import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';

const Forgot = () => {

    const params = useParams()

    const [show, setShow] = useState(true)
    const [none, setNone] = useState(true)
    const [state, setState] = useState({})
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleChange = (event) => {
        if(error === true) {
            setError(false)
        }
        const name = event.target.name;
        const value = event.target.value;
        setState({...state, [name] : value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(state.password !== state.password2) {
            setError(true)
            return
        }
        setLoading(true)
        await forgot(state, params)
                                .then(res => {
                                    if(res !== "error") {
                                        setNone(false)
                                    }
                                }) 
        setLoading(false)
        
    }

    return (
        <div id='login'>
            <Alert onClose={() => setNone(true)} severity="success" className={`alert ${none && 'alert-none'}`}>
                <AlertTitle>Succes</AlertTitle>
                <strong>Mot de passe modifié avec succes</strong>
            </Alert>
            <div className='signin'>
                <div className='signin__wrapper'>
                    <div className='signin__head'>
                        <div className='introduction__desc forgot-center'>Changer le mot de passe</div>
                    </div>
                    <div className='signin__form'>
                        <form onSubmit={handleSubmit}>
                            <div className='signin__box'>
                                <div className='signin__icon'>
                                    <HttpsOutlinedIcon className='blue'/>
                                </div>
                                <input
                                    className='introduction__desc input-2'
                                    type={show === true ? "password" : "text"}
                                    name='password'
                                    value={state.password || ""}
                                    onChange={handleChange}
                                    placeholder='mot de passe'
                                    required
                                />
                                <div className='signin__icon--show' onClick={() => setShow(!show)}>
                                    {show === true ? <VisibilityOutlinedIcon className='blue'/> : <VisibilityOffOutlinedIcon className='blue'/>}
                                </div>
                            </div>
                            <div className='signin__box'>
                                <div className='signin__icon'>
                                    <HttpsOutlinedIcon className='blue'/>
                                </div>
                                <input
                                    className='introduction__desc input-2'
                                    type={show === true ? "password" : "text"}
                                    name='password2'
                                    value={state.password2 || ""}
                                    onChange={handleChange}
                                    placeholder='confirmer le mot de passe'
                                    required
                                />
                                <div className='signin__icon--show' onClick={() => setShow(!show)}>
                                    {show === true ? <VisibilityOutlinedIcon className='blue'/> : <VisibilityOffOutlinedIcon className='blue'/>}
                                </div>
                            </div>
                            {error &&   <div className='login-error introduction__desc'>
                                            mot de passe incorforme
                                        </div>
                            }
                            {
                                loading === true ? <div className='loading'>
                                                        <div className="db-spinner"></div>
                                                    </div> : <button className='signin__submit' type='submit'>
                                                                Changer
                                                            </button>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Forgot