import API from "./Api"
import { getToken } from "./loginService"
import { getOneLocataire } from "./locataireService"
import { defaultIm } from "../utils/nullImg"
import { getAdminLocataire } from "./locataireService"

import img from "../assets/admin.png"
import { getUser } from "./userService"

export const getAllPaiement = async (limit, identifiant) => {

    let type = await getUser()
    type = type['type']

    /*

    [
        {
            "date": "2023-03-27",
            "montant": "80000.00",
            "description": "string",
            "aggregateur": "KKIAPAY",
            "recu": null,
            "type_paiement": "LOYER",
            "frais_divers": null,
            "loyer": 7,
            "locataire": 1
        },
    ]

    */

    const inter = await API.get(`/location/paiement/${identifiant === -1 ? "" : "?locataire="+identifiant}`,
                        {
                            headers: {
                            'content-type': 'multipart/form-data',
                            }
                        })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    let res = [] ;
    let idList = []

    if(type === "REPRESENTANT") {
        let locataire = await getAdminLocataire();

        for(let i = inter.length - 1; i >= 0; i--) {
            for(let j = 0; j < locataire.length; j++) {
                if(inter[i]["locataire"] == locataire[j]["id"] && !idList.includes(locataire[j]["id"])) {
                    idList.push(locataire[j]["id"])
                    res.push(inter[i])
                }
            }
        }
    } else if(type === "SUPERADMIN") {
        res = inter
    }

    /*
    
    {
        "date": "2023-03-27",
        "montant": "80000.00",
        "description": "string",
        "aggregateur": "KKIAPAY",
        "recu": null,
        "type_paiement": "LOYER",
        "frais_divers": null,
        "loyer": 7,
        "locataire": 1
    },

    {
    "id": 2,
    "is_active": true,
    "piece_identite": null,
    "status": "NOUVEAU",
    "photo": "http://3.142.172.6:8000/media/default.png",
    "user": {
      "id": 5,
      "password": "pbkdf2_sha256$390000$WOjWDzO4cnNFX7x8FnmHrl$Vu2yaMq9uOwK2ZraPr5us3Tn5Ibn9gUg3BrRWPTR7vE=",
      "last_login": null,
      "is_superuser": false,
      "email": "uu@example.com",
      "nom": "string",
      "prenom": "string",
      "sexe": "NON_DEFINI",
      "type": "LOCATAIRE",
      "picture": "http://3.142.172.6:8000/media/default.png",
      "telephone": "",
      "birth_date": "2023-03-28",
      "adresse": "",
      "description": "Aucune description",
      "profession": "Aucune rofession",
      "is_admin": false,
      "date_created_at": "2023-03-28T14:12:00.372664Z",
      "date_updated_at": "2023-03-28T14:12:00.372688Z",
      "groups": [],
      "user_permissions": []
    }
    */

    let answer = []

    if (limit === 4) {
        const len = res.length
        const cond = (len >= 4 ? 4 : len)

        for(let i = 0; i < cond; i++) {
            const user = await getOneLocataire(res[i]['locataire'])
            answer.push({
                "img": user['user']['picture'] == defaultIm ? img : user['user']['picture'],
                "title": `${user['user']['prenom']} a envoyé ${res[i]['montant']} FCFA`,
                "date": res[i]['date'],
                "el": user['id'],
            })
        }

    } else {

        /*
            date
            montant
            type
            image
            nom et prenom
        
        */

        for(let i = 0; i < res.length; i++) {
            const user = await getOneLocataire(res[i]['locataire'])
            answer.push({
                "img": user['user']['picture'] == defaultIm ? img : user['user']['picture'],
                "title": user['user']['nom'] + " " + user['user']['prenom'],
                "date": res[i]['date'],
                "montant": res[i]['montant'],
                "type": res[i]['type_paiement'],
                "el": user['id'],
                "recu": res[i]['recu']
            })
        }
    }

    return answer
}

/*

    const dataNotify = [
        {
            "img": notify_1,
            "title": "Ellie a envoyé 15 ajfoiajo",
            "date": "04 Juillet, 2021 | 16:00" 
        },
        {
            "img": notify_2,
            "title": "Jenny a envoyé ajfoiajo ajfoiajoajfoiajo ",
            "date": "04 Juillet, 2021 | 14:00" 
        },
        {
            "img": notify_3,
            "title": "Adam a envoyé ajfoiajo",
            "date": "04 Juillet, 2021 | 11:27" 
        },
        {
            "img": notify_4,
            "title": "Robert a envoyé ajfoiajo",
            "date": "03 Juillet, 2021 | 07:32" 
        }
    ]

*/
