import './App.css';

import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { useState, useEffect } from 'react';

import Home from './pages/home/Home';
import Login from './pages/login/Login';
import Dashboard from './containers/dashboard/Dashboard';
import LocataireList from './containers/locataireList/LocataireList';
import ResidenceList from './containers/residenceList/ResidenceList';
import MultiStepForm from './containers/multiStepForm/MultiStepForm';

import ThemeContext from './utils/themeContext';
import ResidenceForm from './containers/residenceForm/ResidenceForm';
import ChambreList from './containers/chambreList/ChambreList';
import ChambreForm from './containers/chambreForm/ChambreForm';
import PlaintesList from './containers/plaintesList/PlaintesList';
import Stats from './containers/stats/Stats';
import LocataireDetail from './containers/locataireDetail/LocataireDetail';
import Profil from './containers/profil/Profil';
import Parametre from './containers/parametre/Parametre';
import FraisDivers from './containers/fraisDivers/FraisDivers';
import Forgot from './pages/forgot/Forgot';
import SendMail from './pages/sendMail/SendMail';
import ReçuList from './containers/reçuList/ReçuList';
import Assignation from './containers/Assignation/Assignation';

function App() {

  const toggleTheme = () => {
    setState(state => ({
        theme: state.theme === "light"
          ? "dark"
          : "light"
        ,
        toggleTheme: toggleTheme
        })
      )
  }

  const [state, setState] = useState(
    {
      theme: "dark",
      toggleTheme: toggleTheme
    }
  )

  useEffect(()=> {
    if (state.theme === "light") {
      document.querySelector(":root").setAttribute("data-theme", "dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.querySelector(":root").setAttribute("data-theme", "light");
      localStorage.setItem("theme", "light");
    }
  })



  return (
    <ThemeContext.Provider value={state}>
      <div id='App'>
        <BrowserRouter>
          <Routes>
              <Route
                path='/'
                element={<Home />}
              >
                <Route
                  path='dashboard'
                  element={<Dashboard />}
                />
                <Route
                  path='locataires'
                  element={<LocataireList />}
                />
                <Route
                  path='locataires/:id'
                  element={<LocataireDetail />}
                />
                <Route
                  path='locataires/ajouter'
                  element={<MultiStepForm />}
                />
                <Route
                  path='locataires/assigner'
                  element={<Assignation />}
                />
                <Route
                  path='residences'
                  element={<ResidenceList />}
                />
                <Route 
                  path='residences/ajouter'
                  element={<ResidenceForm />}
                />
                <Route 
                  path='residences/:id'
                  element={<ChambreList />}
                />
                <Route 
                  path='residences/:id/chambre'
                  element={<ChambreForm />}
                />
                <Route 
                  path='plaintes'
                  element={<PlaintesList />}
                />  
                <Route 
                  path='/plaintes/lues'
                  element={<PlaintesList 
                    lue = {"LU"}
                  />}
                />
                <Route 
                  path='/parametre'
                  element={<Parametre />}
                />
                <Route 
                  path='/parametre/frais'
                  element={<FraisDivers />}
                />
                <Route 
                  path='/historique'
                  element={<Stats />}
                />
                <Route 
                  path='/profil'
                  element={<Profil />}
                />  
                <Route 
                  path='/reçu'
                  element={<ReçuList />}
                />
                <Route 
                  path='/reçu/:id'
                  element={<ReçuList />}
                />
              </Route>
              <Route
                path='*'
                element={<Home />}
              />
              <Route
                path='/login'
                element={<Login />}
              />
              <Route
                path='/forgot/:uid/:token'
                element={<Forgot />}
              />
              <Route
                path='/forgot'
                element={<SendMail />}
              />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;