/**
 * NOTIFICATION : Quatres derniers état de payement
 */
import React from 'react'
import "./notification.css"

import { Link } from 'react-router-dom'
import Img from '../../utils/Img'
import { defaultIm } from '../../utils/nullImg'
import { API } from '../../utils/const'

const Notification = ({dataNotify}) => {
  return (
    <div id='notification'>
        <div className='notification-title'>
            <div>Notifications</div>
            <div>
                <Link to="/reçu">View All</Link>
            </div>
        </div>
        <div className='notification-content'>
            {dataNotify.map((element, i) => {
                return (
                    <div className='notification--el' key={i}>
                        <div className='notification--img'>
                            <Img 
                                src={element["img"] == `${API}/media/default.png` ? defaultIm : element["img"]}
                                alt={element["date"]}
                            />
                        </div>
                        <div className='notification--word'>
                            <div>{element["title"]}</div>
                            <div>{element["date"]}</div>
                        </div>
                    </div>
                )
                }
            )}
        </div>
    </div>
  )
}

export default Notification