import API from "./Api"
import {getToken} from "./loginService"
import { getAdminLocataire } from "./locataireService"

import {time} from "../utils/time"

/*
    [
        {
            "amount": "90000",
            "percentage": "97"
        },
    ]

    {
        "nb_chambres_occupees": [
            {
                "residence__nom_residence": "HHA",
                "nb_chambres": 2
            },
            {
                "residence__nom_residence": "MAYTON'S LOFT CALAVI",
                "nb_chambres": 5
            }
        ],
        "nb_chambres_libres": [],
        "montants_payes": 25000,
        "montants_impayes": 0,

        "nb_penalite_non_payes": 0,
        "nb_frais_divers_non_payes": 0,

        "montants_frais_non_payees": 0,
        "montants_penalites_non_payees": 0,

        "nb_pen_payees_location_en_cours": 0,
        "montants_penalites_payees_location_en_cours": 0,

        "nb_frais_divers_payes_location_en_cours": 0,
        "montants_frais_payees_en_cours": 0
    }

*/

const process = async (inter) => {
    let answer = {}
    /**
         * CHAMBRE
         */
    let occupé = 0
    let libre = 0

    for (let i = 0; i < inter['nb_chambres_occupees'].length; i++) {
        occupé += parseInt(inter['nb_chambres_occupees'][i]['nb_chambres'])
    }

    for (let i = 0; i < inter['nb_chambres_libres'].length; i++) {
        libre += parseInt(inter['nb_chambres_libres'][i]['nb_chambres'])
    }

    answer['chambres'] = [occupé + libre, [occupé * 100 / (occupé + libre), libre *100 / (occupé + libre)]]

    /**
     * CARD
     */
    // LOYER
    answer['card'] = [
        {
            "amount": inter["montants_payes"],
            "percentage": parseFloat(parseInt(inter["montants_payes"]) * 100 / ((parseInt(inter["montants_impayes"])) + parseInt(inter["montants_payes"]))).toFixed(2)
        },
    ]

    // NOMBRES
    answer['card'].push({
        "amount": inter["montants_penalites_payees_location_en_cours"],
        "percentage": parseFloat(parseInt(inter["montants_penalites_payees_location_en_cours"]) * 100 / ((parseInt(inter["montants_penalites_payees_location_en_cours"])) + parseInt(inter["montants_penalites_non_payees"]))).toFixed(2)
    })

    // FRAIS DIVERS
    answer['card'].push({
        "amount": inter["montants_frais_payees_en_cours"],
        "percentage": parseFloat(parseInt(inter["montants_frais_payees_en_cours"]) * 100 / ((parseInt(inter["montants_frais_payees_en_cours"])) + parseInt(inter["montants_frais_non_payees"]))).toFixed(2)
    })

    // PREPAYER
    answer['card'].push({
        "amount": "0",
        "percentage": "0"
    })

    /**
     * LOCATAIRES
     */
    const locataire = await getAdminLocataire()
    const nbre = locataire.length
    const penalite = inter["nb_pen_payees_location_en_cours"] + inter['nb_penalite_non_payes']
    const frais = inter['nb_frais_divers_payes_location_en_cours'] + inter['nb_frais_divers_non_payes']

    answer['locataires'] = [
        nbre + frais + penalite, [
            nbre * 100 / (nbre + frais + penalite),
            penalite * 100 / (nbre + frais + penalite),
            frais * 100 / (nbre + frais + penalite)
        ]
    ]

    return answer
}

export const getStatsChambre = async () => {
    const idToken = getToken()
    const date = time()
    let answer = {}

    const inter = await API.get(`/location/get_representant_stats_by_filter?month=${date[1]}&year=${date[2]}`,
                            {
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    answer = await process(inter)
    
    return answer
}


/*

const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
const data = labels.map(() => 100*Math.random()) // À revoir

*/

export const label = [
    [31],
    [29],
    [31],
    [30],
    [31],
    [30],
    [31],
    [30],
    [31],
    [30],
    [31],
    [30],
]

/*
    /location/admin_paiement_filter_by_day_month_year?jour=1&mois=1&annee=1'
*/

export const getAdminPaiementDay = async () => {
    const idToken = getToken()
    const date = time()
    let answer = []

    for(let i = 1; i <= date[0]; i++) {

        const inter = await API.get(`/location/paiement/filter-by-date/${date[2]}/${date[1]}/${i}/`,
                            {
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")

        if(inter === "error") {
            return []
        }

        let value = 0
        for(let j=0; j < inter.length; j++) {
            value += parseInt(inter[j]['montant'])
        }

        answer.push(value)
    }
    return answer
}

export const getStatsHistory = async (year, month) => {
    const idToken = getToken()
    let answer = {}

    const inter = await API.get(`/location/get_representant_stats_by_filter?month=${month}&year=${year}`,
                            {
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    answer = await process(inter)
    
    return answer
}

export const getStatusChambre = async (month, year) => {
    const idToken = getToken()
    let answer = []

    const residences = await API.get(`/logistiques/Résidence/`)
                        .then(res => res.data)
                        .catch(_ => "error")

    if(residences != "error") {

        for (let i = 0; i < residences.length; i++) {
            const inter = await API.get(`/location/get_chambres_status?mois=${month}&annee=${year}&residence_id=${residences[i].id}`,
                                {
                                    headers : {
                                        "Authorization" : 'Bearer ' + idToken
                                    }
                                })
                            .then(res => res.data)
                            .catch(_ => "error")


            if(inter != "error") {

                for(let j=0; j < inter.length; j++) {
                    answer.push({
                        residence: residences[i].nom_residence,
                        chambre: inter[j].chambre.designation,
                        chambreId: inter[j].chambre.id,
                        status: inter[j].loyer_status,
                        recu: inter[j].recu == "undefined" ? "" : inter[j].recu
                    })  
                }

            } 
        }
        
        return answer
    } else {
        return residences
    }


}