/**
 * AJOUT DE RESIDENCE
 */
import React from 'react'
import "./residenceForm.css"

import { useState } from 'react'
import {useNavigate} from 'react-router-dom'

import PhotoCameraBackOutlinedIcon from '@mui/icons-material/PhotoCameraBackOutlined';

import { saveResidence } from "../../services/residenceService"

const ResidenceForm = () => {

    const [state, setState] = useState({})
    const [loading, setLoading] = useState(false)

    const handleChange = (event) => {
        const name = event.target.name;
        if (name === "image") {
            setState({...state, [name] : event.target.files[0]});
        } else {
            const value = event.target.value;
            setState({...state, [name] : value});
        }
        
    }

    const navigate = useNavigate()

    /*

        {
            "nom_residence": "string2",
            "description": "string",
            "ville": "string",
            "adresse": "string",
            "arrondissement": "string",
            "quartier": "string",
            "departement": "string",
            "pays": "string",
            "coordonne_geo": "string"
        }

    */

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        const response = await saveResidence(state)
        setLoading(false)
        if(response !== "error") {
            navigate(`/residences`);
        }
    }

    const comp = (
        <form onSubmit={handleSubmit}>
            <div id="residenceForm">
                <div className='residenceForm__wrapper'>
                    <div className='multiStepForm__header'>
                        <div className='multiStepForm__title'>Création  de résidence</div>
                    </div>
                    <div className='residenceForm__body'>
                        <div className='residenceForm__body__wrapper'>
                            <div className='residenceForm__form__flex'>
                                <div className='residenceForm__form__box'>
                                    <label>Nom</label>
                                    <input 
                                        type='text'
                                        name='nom_residence'
                                        placeholder='La gloire'
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className='residenceForm__form__box'>
                                    <label>Quatier</label>
                                    <input 
                                        type='text'
                                        name='quartier'
                                        placeholder='Zogbadjé'
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className='residenceForm__form__box'>
                                    <label>Arrondissement</label>
                                    <input 
                                        type='text'
                                        name='arrondissement'
                                        placeholder='Godomey'
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='residenceForm__form__flex'>
                                <div className='residenceForm__form__box'>
                                    <label>Ville</label>
                                    <input 
                                        type='text'
                                        name='ville'
                                        placeholder='Abomey-calavi'
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className='residenceForm__form__box'>
                                    <label>Département</label>
                                    <input 
                                        type='text'
                                        name='departement'
                                        placeholder='Atlantique'
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className='residenceForm__form__box'>
                                    <label>Pays</label>
                                    <input 
                                        type='text'
                                        name='pays'
                                        placeholder='Bénin'
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='residenceForm__form__flex'>
                                <div className='residenceForm__form__box__second'>
                                    <label>Description <span className='red'>"format court"</span></label>
                                    <textarea 
                                        type='text'
                                        name='description'
                                        onChange={handleChange}
                                        required
                                    >
                                    </textarea>
                                </div>
                                <div className='form__box__second'>
                                    <div className='residenceForm__form__flex__second'>
                                        <div className='residenceForm__form__box__second'>
                                            <label>Adresse</label>
                                            <input 
                                                id='adresse'
                                                type='text'
                                                name='adresse'
                                                placeholder='Cotonou, Rue 450'
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className='form__box__file'>
                                            <label>Photo</label>
                                            <label className='form__file' htmlFor="photo">
                                                <div className='form__file__but'>
                                                    <PhotoCameraBackOutlinedIcon/>
                                                </div>
                                            </label>
                                            <input 
                                                type='file'
                                                id='photo'
                                                name='image'
                                                accept=".jpg, .jpeg, .png"
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='residenceForm__footer'>
                        <button className='multiStepForm__but' type='submit'>
                            <span>Soumettre</span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )

    return (loading === true) ? <div className='loading'>
                                    <div className="db-spinner"></div>
                                </div> : comp
}

export default ResidenceForm