export const time = () => {
    const date = new Date();
    return [
        parseInt(date.getDate()),
        parseInt(date.getMonth()) + 1,
        parseInt(date.getFullYear())
    ]
}

export const getYear = (date) => {
    return parseInt(date.slice(0, 4))
}

export const getMonth = (date) => {
    return parseInt(date.slice(5, 7))
}

export const getDate = (date) => {
    return parseInt(date.slice(8, 10))
}

export const monthData = [
    '',
    'Janvier',
    'Fevrier',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre'
]

/*
    2023-02-01

    console.log(date.getDay())
    console.log(date.getMonth())
    console.log(date.getFullYear())
    console.log(date.getDate())
*/