/**
 * RECU list
 */
import React from 'react'
import { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom'
import Modal from 'react-modal';

import DataTable from 'react-data-table-component';
import Img from '../../utils/Img';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import ThemeContext from '../../utils/themeContext';
import { getAllPaiement } from '../../services/paiementService';

import PDFViewerComponent from '../../utils/pdfReader'
import { API } from '../../utils/const';
import { defaultIm } from '../../utils/nullImg';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
};

const ReçuList = () => {

    const id = useParams().id
    const [ paiement, setPaiement ] = useState(0)
    const [recu, setRecu] = useState(0)

    /*
        [
            {
                "date": "2023-03-27",
                "montant": "80000.00",
                "description": "string",
                "aggregateur": "KKIAPAY",
                "recu": null,
                "type_paiement": "LOYER",
                "frais_divers": null,
                "loyer": 7,
                "locataire": 1
            },
        ]
    */

    useEffect(() => {
        if(id === undefined) {
            getAllPaiement(0, -1)
            .then((res) => {
                setPaiement(res)
            })
        } else {
            getAllPaiement(0, id)
            .then((res) => {
                setPaiement(res)
            })
        }
    }, [id])

    Modal.setAppElement(document.getElementById('root'));

    const [modalIsOpen, setIsOpen] = useState(false);

    const closeModal = () => {
        setIsOpen(false)
    }

    const columns = [
        {
            name: 'Image',
            selector: row => <Link to={`/reçu/${row.el}`} className='datatable__img'><Img src={row.img == `${API}/media/default.png` ? defaultIm : row.img} alt={"user" + row.el} /></Link>,
            },
        {
            name: 'Nom et prenoms',
            selector: row => <Link to={`/reçu/${row.el}`} className='user__infos'>{row.title}</Link>,
            sortable: true,
        },
        {
            name: 'Date de paiement',
            selector: row => <Link to={`/reçu/${row.el}`} className='user__infos'>{row.date}</Link>,
        },  
        {
            name: 'Montant',
            selector: row => <Link to={`/reçu/${row.el}`} className='user__infos'>{row.montant}</Link>,
            sortable: true,
        },
        {
            name: 'Type de paiement',
            selector: (row) => {
                if(row.type_paiement === "LOYER") {
                    return <Link to={`/reçu/${row.id}`} className='user__infos'><span className='green'>{row.type}</span></Link>
                } else {
                    return <Link to={`/reçu/${row.id}`} style={{color: "rgba(157, 155, 244, 1)"}}><span className='blue'>{row.type}</span></Link>
                }
            },
        },
        {
            name: " Modifer",
            selector: (row) => (<button 
              className='multiStepForm__but'
              onClick={e => {
                setIsOpen(true)
                setRecu(row.recu)
              }}
            ><VisibilityOutlinedIcon /></button>),
        }
    ];

    return (
        <ThemeContext.Consumer>
        {({theme, _}) => (
          <div>
            <div id='locataires'>
                <div className='locataires__header'>
                    <div className='multiStepForm__header'>
                        <div className='multiStepForm__title'>Liste des paiements</div>
                    </div>
                </div>
                <div className='locataires__datatable'>
                <div className='locataires__datatable__wrapper'>
                    {paiement === 0 ? <div className='loading'>
                                        <div className="db-spinner"></div>
                                    </div> :
                                    <DataTable
                                        columns={columns}
                                        data={paiement}
                                        pagination
                                        theme={ theme === "dark" ? "default" : "dark" }
                                    />
                    }
                </div>
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {recu === "" ? <div className='loading'>
                        <div className="db-spinner"></div>
                    </div> :<PDFViewerComponent 
                        url={recu}
                    />}
            </Modal>
          </div>
        )}
    </ThemeContext.Consumer>
    )
}

export default ReçuList