import React from 'react'

import PDFViewer from 'pdf-viewer-reactjs'

const PDFViewerComponent = ({url}) => {
    return (
        <div>
            <PDFViewer
                scale={1.25}
                loader={true}
                hideNavbar={true}
                document={{
                    url: url,
                }}
            />
        </div>
    )
}

// import { Document, Page } from 'react-pdf-renderer';
// function PDFViewerComponent() {
//   return (
//     <Document file="example.pdf">
//       <Page pageNumber={1} />
//     </Document>
//   );
// }

// import { Document } from 'react-pdf'
// function PDFViewerComponent(url) {
//   return (
//     <Document file={url} />
//   );
// }

export default PDFViewerComponent
