import API from "./Api"
import {getToken} from "./loginService"

let idToken = getToken()

export const getCategory = async () => {
    const idToken = getToken()
    const inter = await API.get(`/logistiques/Category/`,{
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    return inter
}

export const putAllCategory = async (data) => { 
    for(let i = 0; i < data.length; i++) {
        await putCategory(data[i], data[i]['id'])
    }
}

export const putCategory = async (data, id) => {
    const inter = await API.put(`/logistiques/Category/${id}/`,
                            {
                                "denomination": data["denomination"],
                                "montant_penalite_tranche_1": data["montant_penalite_tranche_1"],
                                "montant_penalite_tranche_2": data["montant_penalite_tranche_2"],
                                "montant_penalite_tranche_3": data["montant_penalite_tranche_3"]
                            },
                            {
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    return inter
}