/**
 * ASSIGNATION DE CHAMBRE 
 * Choix des locataire
 * Assignation de chambre (Creation de location)
 */
import React from 'react'
import './assignation.css'

import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import ThemeContext from '../../utils/themeContext';
import { getLocataire, getAdminLocataire} from '../../services/locataireService'

import { useNavigate } from 'react-router-dom';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';

import {getResidence, getAllChambre } from "../../services/residenceService"
import {assigneLocataire} from "../../services/locationService"

const Assignation = () => {

    const [state, setState] = useState({
        "type" : "LOCATAIRE"
    })
    const [select, setSelect] = useState("la glore")
    const [dataResidence, setDataResidence] = useState(0)
    const [dataChambre, setDataChambre] = useState(0)
    const [locataires, setLocataires] = useState(0)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        getAllChambre().then(
            (chambre) => {
                getResidence().then(
                    (res) => {
                        let dataTest = []
                        let setForChambre = {}
        
                        // Mise en place des dictionnaire {'residence': [chambre, ...]}
                        for(let i = 0; i < res.length; i++) {
                            dataTest.push([res[i]['nom_residence']])
                            setForChambre[res[i]['nom_residence']] = [["Séléctionnez une chambre", ""]]
                            for(let j = 0; j < chambre.length; j++) {
                                if(chambre[j]['residence'] == res[i]['id'] && chambre[j]["statut"] != "OCCUPE") {
                                    setForChambre[res[i]['nom_residence']].push([chambre[j]['designation'], chambre[j]['id']])
                                }
                            }
                        }
        
                        setDataResidence(dataTest)
                        setDataChambre(setForChambre)
                        setSelect(dataTest[0][0])
                        
                        getAdminLocataire()
                        .then(res => {
                            getLocataire(res)
                            .then(list => {
                                setLocataires(list)
                            })
                        })
                    }
                )
            }
        )
    }, [])

    const handleChange = async (event) => {
        const name = event.target.name;
        let value;
        if(name === "contrat" || name === "piece") {
            value = await event.target.files[0]
        } else {
            value = event.target.value;
        }
        setState({...state, [name] : value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        await assigneLocataire({...state})
                .then(res => {
                    navigate('/locataires')
                })
        setLoading(false)
    }

    /**
     * STEP MANAGE
     */
    const [index, setIndex] = useState(1)
    const precedent = () => {
        if(index > 1) {
            setIndex(index - 1)
        }
    }
    const suivant = () => {
        if(index < 2) {
            setIndex(index + 1)
        }
    }

    const handleSelect = (event) => {
        if(event.target.value != select) {
            setSelect(event.target.value)
        }
    }

    const columns = [
        {
            name: 'id',
            selector: row => row.id,
        }, 
        {
            name: 'Nom et prenoms',
            selector: row => row.user.nom + " " + row.user.prenom,
            sortable: true,
        },
         
    ];

    return ( dataResidence === 0 || dataChambre === 0 || loading === true ? <div className='loading'>
                                <div className="db-spinner"></div>
                            </div> : (<ThemeContext.Consumer>
          {({theme, _}) => (
            <form onSubmit={handleSubmit}>
                <div id='multiStepForm'>
                    <div className='multiStepForm__wrapper'>
                        <div className='multiStepForm__header'>
                            <div className='multiStepForm__title'>Assignation d'une chambre</div>
                        </div>
                        <div className='multiStepForm__body'>
                            <div className='multiStepForm__body__wrapper'>
                                <div className='multiStepForm__progress'>
                                    <ProgressBar
                                        percent={(index - 1) * 100 }
                                        filledBackground="#f7f7f7, #306FEB"
                                    >
                                        <Step transition="scale">
                                        {({ accomplished }) => (
                                            <div className={`step ${accomplished ? "completed" : ""}`}>1</div>
                                        )}
                                        </Step>
                                        <Step transition="scale">
                                        {({ accomplished }) => (
                                            <div className={`step ${accomplished ? "completed" : ""}`}>2</div>
                                        )}
                                        </Step>
                                    </ProgressBar>
                                </div>
                                <div className={`multiStepForm__form ${index === 1 ? "" : "none"}`}>
                                    <div className='form__wrapper'>
                                        <div className='form__header'>
                                            Informations personnelles
                                        </div>
                                        <div className='form__box w100'>
                                            <label>identifiant du locataire</label>
                                            <input 
                                                type='number'
                                                name='id'
                                                value={state.id || ""}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                            <div className='locataires__datatable__wrapper'>
                                                {locataires === 0 ? <div className='loading'>
                                                                        <div className="db-spinner"></div>
                                                                    </div> :
                                                                    <DataTable
                                                                        columns={columns}
                                                                        data={locataires}
                                                                        pagination
                                                                        theme={ theme === "dark" ? "default" : "dark" }
                                                                    />
                                                }
                                            </div>
                                    </div>
                                </div>
                                <div className={`multiStepForm__form ${index === 2 ? "" : "none"}`}>
                                    <div className='form__wrapper'>
                                        <div className='form__header'>
                                            Informations de location
                                        </div>
                                        <div className='form__flex'>
                                            <div className='form__box'>
                                                <label>Résidence <span className='red'> *cliquer pour choisir</span></label>
                                                <div id='select'>
                                                    <select onClick={(e) => {
                                                            handleChange(e)
                                                            handleSelect(e)
                                                        }
                                                    } name="residence">
                                                        {dataResidence.map((data, i) =>
                                                            <option value={data} key={`${data} ${i}`}>{data}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='form__box'>
                                                <label>Chambre <span className='red'> *cliquer pour choisir</span></label>
                                                <div id='select'>
                                                    <select onClick={handleChange} name="chambre">
                                                        {typeof(dataChambre[select]) !== "undefined" && dataChambre[select].map((data, i) =>
                                                            <option value={data[1]} key={`${select} ${i}`}>{data[0]}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form__flex'>
                                            <div className='form__box'>
                                                <label>Date de départ</label>
                                                <input 
                                                    type='date'
                                                    name='date_depart'
                                                    value={state.date_depart || ""}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className='form__box'>
                                                <label>Date de fin</label>
                                                <input 
                                                    type='date'
                                                    name='date_fin'
                                                    value={state.date_fin || ""}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='form__flex__file'>
                                            <div className='form__box__file'>
                                                <label>Piece</label>
                                                <label className='form__file' htmlFor="piece">
                                                    <div className='form__file__but'>
                                                        <DriveFolderUploadOutlinedIcon/>
                                                    </div>
                                                </label>
                                                <input 
                                                    type='file'
                                                    id='piece'
                                                    name='piece'
                                                    onChange={handleChange}
                                                    accept=".jpg, .jpeg, .png, .pdf"
                                                    required
                                                />
                                            </div>
                                            <div className='form__box__file'>
                                                <label>Contrat</label>
                                                <label className='form__file' htmlFor="contrat">
                                                    <div className='form__file__but'>
                                                        <DriveFolderUploadOutlinedIcon/>
                                                    </div>
                                                </label>
                                                <input 
                                                    type='file'
                                                    id="contrat"
                                                    name='contrat'
                                                    onChange={handleChange}
                                                    accept=".jpg, .jpeg, .png, .pdf"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='multiStepForm__footer'>
                            <div disabled={index===1} onClick={precedent} className='multiStepForm__but'>
                                <ArrowBackOutlinedIcon /> <span>Précedent</span>
                            </div>
                            {index === 2 ?
                                <button className='multiStepForm__but' type='submit'>
                                    <span>Soumettre</span>
                                </button> : <div className='multiStepForm__but' onClick={suivant}>
                                                <span>Suivant</span><ArrowForwardOutlinedIcon /> 
                                            </div>
                            }
                        </div>
                    </div>
                </div>
            </form>)}
        </ThemeContext.Consumer>
    ))
}

export default Assignation