/**
 * CHART DE SUIVI DES PAYEMENTS
 */

import React from 'react'
import "./chart.css"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

const Chart = (props) => {

    // Configuration du chart
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );
    
    const options = {
        responsive: true,
        animations: {
          tension: {
            duration: 1000,
            easing: 'linear',
            from: 0.8,
            to: 0.2,
            loop: true
          }
        },
        plugins: {
            legend: {
                position: 'bottom',
                display: false
            },
            swcMinify: false,
        },
        scales: {
            x: {
              border: {
                display: false
              },
              grid: {
                display: false,
              }
            },
            y: {
              border: {
                display: true,
              },
              grid: {
                display: true,
                drawTicks: true,
              },
            }
        }
    };

    const labels = props.labels

    const data = {
      labels,
      datasets: [
        {
            data: props.data,
            borderColor: 'rgba(48, 111, 235, 1)',
            backgroundColor: 'rgba(48, 111, 235, 1)'
        }
      ],
    };
  
  return (
    <div id='chart'>
      <div className='chart-title'>
          <div>Aperçu des payements</div>
          <div>Mois de {props.month}</div>
      </div>
      <div className="chart__el">
        <div className='chart__flex'>
            <Line options={options} data={data} />
        </div>
      </div>
    </div>
  )
}

export default Chart