/**
 * HISTORIQUE
 */
import React from 'react'
import './stats.css'

import { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'

import PrimaryCard from '../../components/primaryCard/PrimaryCard'
import { monthData, time } from '../../utils/time'

import Repartition from '../../components/repartition/Repartition'
import { getStatsChambre, getStatsHistory, getStatusChambre } from '../../services/stats'

import { getUser } from '../../services/userService'
import Modal from 'react-modal';

import DataTable from 'react-data-table-component';
import ThemeContext from '../../utils/themeContext';
import PDFViewerComponent from '../../utils/pdfReader'

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
};

const Stats = () => {
    const year = time()[2]

    const [monthSelect, setMonthSelect] = useState(time()[1])
    const [yearSelect, setYearSelect] = useState(year)
    const [state, setState] = useState(0)
    const [status, setStatus] = useState([])

    Modal.setAppElement(document.getElementById('root'));

    const [modalIsOpen, setIsOpen] = useState(false);
    const [recu, setRecu] = useState(0)

    const closeModal = () => {
        setIsOpen(false)
    }

    useEffect(() => {
        getUser()
        .then(type => {
            if(type['type'] === "SUPERADMIN") {
                // getStatsChambre()
                // .then((res) => {
                //     setState(res)
                // })
            } else if(type['type'] === "REPRESENTANT") {
                getStatsChambre()
                .then((res) => {
                    console.log(res)
                    setState(res)
                })
            }
        })
        // getPaiement()
    }, [])

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        if ( name === "month") {
            setMonthSelect(value)
        } else if(name === "year") {
            setYearSelect(value)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setState(0)
        // Load data for this period
        await getStatsHistory(yearSelect, monthSelect)
                .then((res) => {
                    setState(res)
                })
        
        await getStatusChambre(monthSelect, yearSelect)
                .then((res) => {
                    setStatus(res)
                })
        
    }

    let listMonth = {}

    // Generation de mois et année valide
    for(let i = 2023; i <= year; i++) {
        listMonth[`${i}`] = []
        for(let j = 1; j <= 12; j++) {
            if(year !== 2023) {
                if(i === 2023 && j >= 5) {
                    listMonth[`${i}`].push(j)
                } else if (i == year && j <= time()[1]) {
                    listMonth[`${i}`].push(j)
                } else if(i > 2023 && i < year) {
                    listMonth[`${i}`].push(j)
                }
            } else {
                if(j >= 5 && j <= time()[1]) {
                    listMonth[`${i}`].push(j)
                }
            }
        }
    }

    let listYear = []

    for (let i = 2023; i <= year; i++) {
        listYear.push(i)
    }

    useEffect(() => {
        const date = time()
        console.log("DATE ", date)
        getStatusChambre(date[1], date[2])
            .then((res) => {
                console.log("STATUS ", res)
                setStatus(res)
            })
    }, [])


    const columns = [
        {
            name: 'Residences',
            selector: row => <div className='user__infos'>{row.residence}</div>,
            sortable: true,
        }, 
        {
            name: 'Chambres',
            selector: row => <div className='user__infos'>{row.chambre}</div>,
            sortable: true,
        }, 
        {
          name: 'Status',
          selector: (row) => {
            if(row.status === "payé") {
              return <div className='user__infos'><span className='green'>{row.status}</span></div>
            } else {
              return <div className='user__infos'><span className='blue'>{row.status}</span></div>
            }
          },
        },
        {
            name: 'Status',
            selector: row => <Link to={row.recu} className='user__infos'>{row.chambre}</Link>,
        },
        {
            name: "Voir le reçu",
            selector: (row) => (<button 
              className='multiStepForm__but'
              onClick={e => {
                setIsOpen(true)
                setRecu(row.recu)
              }}
            ><VisibilityOutlinedIcon/></button>),
        }
      ];

    return ( <ThemeContext.Consumer>
        {({theme, _}) => (
        <div id='dashboard'>
            <div className='dashboard__header'>
                <div className='dashboard__title'>Allez dans l’historique en fonction du mois et de l’année</div>
                <div className='dashboard__header__select'>
                    <form onSubmit={handleSubmit}>
                        <div className='dashboard__select'>
                            <div className='dashboard__select__wrapper'>
                                <select onClick={handleChange} name="year">
                                    {listYear.reverse().map((data, i) => {
                                        return <option value={data} key={i}>{data}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className='dashboard__select'>
                            <div className='dashboard__select__wrapper'>
                                <select onClick={handleChange} name="month" defaultValue={monthSelect}>
                                    {listMonth[`${yearSelect}`].map((data, i) => {
                                        return <option value={data} key={i}>{monthData[data]}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <button type='submit'>Validé</button>
                    </form>
                </div>
            </div>
            { state === 0 ? <div className='loading'>
                <div className="db-spinner"></div>
            </div> : <div id='dashboard'>
                <div className='dashboard__cards'>
                    <PrimaryCard 
                        title={"Loyer"} 
                        amount={state["card"][0]['amount']}
                        percentage={state["card"][0]['percentage']}
                        pie={true}
                    />
                    <PrimaryCard 
                        title={"Pénalité"} 
                        amount={state["card"][1]['amount']}
                        percentage={state["card"][1]['percentage']}
                        pie={true}
                    />
                    <PrimaryCard 
                        title={"Frais divers"} 
                        amount={state["card"][2]['amount']}
                        percentage={state["card"][2]['percentage']}
                        pie={true}
                    />
                    <PrimaryCard 
                        title={"Prépayer"} 
                        amount={state["card"][3]['amount']}
                        percentage={state["card"][3]['percentage']}
                        pie={false}
                    />
                </div>
                <div className='dashboard__pie'>
                    <Repartition 
                        name="loyer + frais + pénalité" 
                        percentage={state['locataires'][1]}
                        number={state['locataires'][0]}
                    />
                    <Repartition 
                        name="chambres"
                        percentage={state['chambres'][1]}
                        number={state['chambres'][0]}
                    />
                </div>
                <div className='locataires__header'>
                    <div className=''>
                        <div className='multiStepForm__title'>Statut des paiements</div>
                    </div>
                </div>
                <div className='locataires__datatable'>
                    <div className='locataires__datatable__wrapper'>
                        {status != "error" && status.length > 0 && <DataTable
                            columns={columns}
                            data={status}
                            pagination
                            theme={ theme === "dark" ? "default" : "dark" }
                        />}
                    </div>
                </div>
                

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {recu === "" ? <div className='loading'>
                        <div className="db-spinner"></div>
                    </div> :<PDFViewerComponent 
                        url={recu}
                    />}
            </Modal>
                
            </div> 
        }
            
        </div>) }
        </ThemeContext.Consumer> 
    )
}

export default Stats