/**
 * Creation de frais divers
 */
import React from 'react'
import "./fraisDivers.css"

import {useState, useEffect} from 'react';
import DataTable from 'react-data-table-component';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Modal from 'react-modal';

import ThemeContext from '../../utils/themeContext';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { createFrais } from '../../services/fraisService';
import { getResidence, getChambre } from '../../services/residenceService';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
};

const FraisDivers = () => {

    const [dataStats, setDataStats] = useState(0)
    const [load, setLoad] = useState(false)

    useEffect(() => {
        // Gestion des residences => frais divers relatif à une chambre
        getResidence()
        .then(
            (res) => {
                let chambres = []
                for(let i = 0; i < res.length; i++) {
                    let chambre; 
                    getChambre(res[i]['id'])
                    .then((ans) => {
                        chambre = ans
                        for(let j = 0; j < ans.length; j++) {
                            if(chambre[j]['statut'] === "OCCUPE") {
                                chambre[j]['residence'] = res[i]['nom_residence']
                                chambres.push(chambre[j])
                            }
                        }
                    })
                    
                }
                setDataStats(chambres)
            }
        )

    }, [])

    const columns = [
        {
          name: 'id',
          selector: row => row.id,
        },
        {
            name: 'designation',
            selector: row => row.designation,
            sortable: true,
        },
        {
          name: 'résidence',
          selector: row => row.residence,
        },
    ];

    // Etat des routes selectionné dans le datatable
    const [selectedRows, setSelectedRows] = useState([]);

	const [data, setData] = useState([]);

    Modal.setAppElement(document.getElementById('root'));

    const [state, setState] = useState({})

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setState({...state, [name] : value});
    }

    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        // Gestion des chambre selectionnés
        setIsOpen(false)
        if(data.length === 0) {
            setData([...selectedRows])
        } else {
            let rowsSelected = [];
            let check;
            // Filtrage et affichages des selections
            for (var i = 0; i < selectedRows.length ; i++) {
                check = true
                // Voir si la chambre a déjà été selectionnée
                for (var j = 0; j < data.length ; j++) {
                    if (selectedRows[i]["id"] == data[j]['id']) {
                        check = false
                    }
                }
                if (check === true) {
                    rowsSelected.push(selectedRows[i])
                }
            }
            setData([...data, ...rowsSelected])
            setSelectedRows([])
        }
    }

    const handleRowSelected = stateData => {
		setSelectedRows(stateData.selectedRows);
	}

    const handleDelete = (event) => {
        event.preventDefault();
        const id = event.target.parentNode.parentNode.id
        setData(data.filter(data__index => data__index.id !== id))
    }

    const handleSubmit = async (event) => {
        setLoad(true)
        event.preventDefault()
        await createFrais(state, data)
            .then(_ => {
                setState({})
                setData([])
            })
        setLoad(false)
    }

    return (
        <React.Fragment>
            <div id='fraisDivers'>
                {load === true ? <div className='loading'>
                                          <div className="db-spinner"></div>
                                      </div> : <form className='fraisDivers__wrapper' onSubmit={handleSubmit}>
                    <div className='multiStepForm__header'>
                        <div className='multiStepForm__title'>Création de frais divers</div>
                    </div>
                    <div className='fraisDivers__body'>
                        <div className='fraisDivers__body__wrapper'>
                            <div className='fraisDivers__body__flex'>
                                <div className='fraisDivers__body__box'>
                                    <label>Montant</label>
                                    <input 
                                        type='number'
                                        name='montant'
                                        placeholder='10000'
                                        value={state['montant']|| "" }
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className='fraisDivers__body__box'>
                                    <label>Type</label>
                                    <div className='fraisDivers__body__box__select'>
                                        <select onChange={handleChange} name="description" required>
                                            <option value="">Sélectionner une description</option>
                                            <option value="frais de copropriété">frais de copropriété</option>
                                            <option value="frais de chauffage">frais de chauffage</option>
                                            <option value="frais d'eau">frais d'eau</option>
                                            <option value="frais d'électricité">frais d'électricité</option>
                                            <option value="frais de vidange">frais de vidange</option>
                                            <option value="frais de nettoyage">frais de nettoyage</option>
                                            <option value="frais de gardiennage">frais de gardiennage</option>
                                            <option value="frais d'internet">frais d'internet</option>
                                            <option value="autre">Autres</option>     
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='fraisDivers__body--user'>
                                {data.map((data__index) => {    
                                    return (
                                        <div className='user__select' key={data__index['id']} id={data__index['id']}>
                                            <div className='user--nom' >{data__index['designation']}</div>
                                            <button onClick={handleDelete} className='user__cross__button'>
                                                <ClearOutlinedIcon />
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>
                            {dataStats === 0 ? "" : <div className='parametre__header__button'>
                                <div className='locataires__ajout' onClick={() => {
                                    openModal()
                                }}>
                                    <AddOutlinedIcon /><span>Ajouter une chambre</span>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className='fraisDivers__footer'>
                        <button className='multiStepForm__but' type='submit'>
                            <span>Soumettre</span>
                        </button>
                    </div>
                </form> }
            </div>
            <div>
                <ThemeContext.Consumer>
                {({theme, _}) => (
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <div className='frais__modal'>
                        {   dataStats === 0 ? <div className='loading'>
                                        <div className="db-spinner"></div>
                                    </div> :
                            <DataTable
                                columns={columns}
                                data={dataStats}
                                pagination
                                selectableRows
                                onSelectedRowsChange={handleRowSelected}
                                theme={ theme === "dark" ? "default" : "dark"}
                            />
                        }
                        </div>
                    </Modal>
                )}
                </ThemeContext.Consumer>
            </div>
        </React.Fragment>
    )   
}               

export default FraisDivers