import API from "./Api"
import { getToken } from "./loginService"
import { getAdminLocataire } from "./locataireService"

import { getOneLocataire } from "./locataireService"

// {
//     "id": 1,
//     "titre": "string",
//     "description": "string",
//     "date_envoi": "2023-03-25",
//     "status": "NON_LU",
//     "message_reponse": "string",
//     "location": 2
// }

// {
//     "id": 1,
//     "is_active": true,
//     "piece_identite": null,
//     "status": "NOUVEAU",
//     "photo": "http://3.142.172.6:8000/media/default.png",
//     "user": {
//       "id": 1,
//       "password": "pbkdf2_sha256$390000$rqsLljZVqyzRIkUMSHrVb0$Lo2AYeeBRrOtFErOU3BN2df4QlUOUBf8aNIGoatEYiM=",
//       "last_login": null,
//       "is_superuser": false,
//       "email": "hernandezdecos96@gmail.com",
//       "nom": "DOE",
//       "prenom": "John",
//       "sexe": "NON_DEFINI",
//       "type": "LOCATAIRE",
//       "picture": "http://3.142.172.6:8000/media/default.png",
//       "telephone": "",
//       "birth_date": "2023-03-27",
//       "adress": "",
//       "is_admin": false,
//       "date_created_at": "2023-03-27T18:08:52.652399Z",
//       "date_updated_at": "2023-03-27T18:08:52.652429Z",
//       "groups": [],
//       "user_permissions": []
//     }
// }

// [
//     {
//       "id": 1,
//       "date_depart": "2023-02-01",
//       "date_fin": "2023-09-01",
//       "status": "EXPIRE",
//       "contrat": "http://3.142.172.6:8000/media/maquette_4vAFl9d.png",
//       "signature": null,
//       "prix_loc": "560000.00",
//       "mod_paiement": "MENSUEL",
//       "prefs_paiement": "PREPAYE",
//       "chambre": 1,
//       "locataire": 1
//     },

// Proprietiaire service

export const getPlainte = async (type) => {
    const idToken = getToken()

    const inter = await API.get('/location/plaintes/', {
                            headers : {
                                "Authorization" : 'Bearer ' + idToken
                            }
                        })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    if(type === 1) {
        return inter
    }

    const res = await API.get('/location/location_infos/by_all_without_contrainte/', {
                            headers : {
                                "Authorization" : 'Bearer ' + idToken
                            }
                        })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(res === "error") {
        return []
    }

    const locataire = await getAdminLocataire()

    let response = []

    // res : liste de tous les infos de location 
    // inter : liste des plaintes
    // locataire : liste des locataires de l'admin

    for(let i = 0; i < locataire.length; i++) {
        for(let j = 0; j < res.length; j++) {
            if(res[j]["locataire"] == locataire[i]["id"]) {
                for(let k = 0; k < inter.length; k++) {
                    if (inter[k]["location"] == res[j]['id']) {
                        inter[k]["user"] = await getOneLocataire(res[k]["locataire"])
                            .then(ans => {
                                return ans.user
                            })
                        response.push(inter[k])
                    }
                }
            }
        }
    }

    return response
}

export const getImg = async (idLocation) => {
    const idToken = getToken()
    const inter = await API.get(`/location/LocataireAdminManage/${idLocation}/`, {
                            headers : {
                                "Authorization" : 'Bearer ' + idToken
                            }
                        })
                        .then(res => res.data['user']["picture"])
                        .catch(_ => "error")
    
    if(inter === "error") {
        return []
    }

    return inter
}

export const putStatus = async (data) => {
    const idToken = getToken()
    const inter = await API.patch(`/location/plaintes/${data.id}/`,
                            data
                            ,{
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    return inter
}