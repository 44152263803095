/**
 * HOME : Switch des composants (outlet)
 */
import React from 'react'
import { useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import "./home.css"

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/Sidebar/Sidebar'

import {getUser} from "../../services/userService"
import {checkConnect} from "../../services/loginService"
import { getPlainte } from '../../services/plainteService'

import {API} from "../../utils/const"

const Home = () => {

  const navigate = useNavigate()
  const [file, setFile] = useState()
  const [nom , setNom] = useState("")
  const [state, setState] = useState([])
  const [notify, setNotify] = useState(false)

  useEffect(() => {
    // AUTH
    checkConnect().then(res => {
      if (res === true) {
        navigate('/login')
      } else {
        // INFOS DE L'UTILISATEUR CONNECTER
        getUser()
        .then(response => {
          if(response === "error") {
            navigate('/login')
          }
          setState(response)
          if (response.length !== 0) {

            setNom(response.nom)
            setFile(API + response.picture_url)
            // VOIR SI IL Y A DE PLAINTES NON LUS
            getPlainte(1)
            .then(plainte => {



              let enter = 0
              for(let i = 0; i < plainte.length; i++) {
                if("NON_LU" === plainte[i]['status']) {
                  setNotify(false)
                  enter += 1
                  break
                }
              }
              if (enter === 1) {
                setNotify(true)
              }
            })
          }
        }).catch(_ => {
          navigate('/login')
        })
      }
    })
  }, [navigate]);

  return (
    state.length !== 0 ? ( <div id='home'>
                  <Sidebar />
                  <div className='home__body'>
                    <Navbar 
                      nom={nom}
                      file={file}
                      notify={notify}
                    />
                    <div className='home__content'>
                      <Outlet />
                    </div>
                  </div>
                </div>) : (<div className='loading'>
                    <div className="db-spinner"></div>
                </div>)
    )
}

export default Home