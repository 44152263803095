/**
 * DASHBOAR
 *  |- CARD         : loyer, fais divers, prepayer, penalité
 *  |- CHART        : flux de paiement
 *  |- Notification : quatres derniers notif de paiements
 *  |- Repartition  : Locataire, chambres
 * 
 */
import React from 'react'
import "./dashboard.css"

import { useEffect, useState } from 'react'

import PrimaryCard from '../../components/primaryCard/PrimaryCard'
import Chart from '../../components/chart/Chart'

import Repartition from '../../components/repartition/Repartition'
import Notification from '../../components/notification/Notification'

import { getStatsChambre, label, getAdminPaiementDay } from '../../services/stats'
import { getAllPaiement } from '../../services/paiementService'

import { monthData, time } from '../../utils/time'
import { getUser } from '../../services/userService'

const Dashboard = () => {

    const [state, setState] = useState(0)
    const [data, setData] = useState(0)
    const [notify, setNotify] = useState(0)

    useEffect(() => {
        getUser()
        .then(type => {
            getAdminPaiementDay()
            .then(res => setData(res))
            if(type['type'] === "SUPERADMIN") {
                // getStatsChambre()
                // .then((res) => {
                //     setState(res)
                // })
                // getAllPaiement(4, -1)
                // .then((res) => {
                //     setNotify(res)
                // })
            } else if(type['type'] === "REPRESENTANT") {
                getStatsChambre()
                .then((res) => {
                    setState(res)
                })
                getAllPaiement(4, -1)
                .then((res) => {
                    setNotify(res)
                })
            }
        })
    }, [])

    // Liste des jours du mois actuel
    let labels = [];

    for(let i = 1; i <= label[time()[1] - 1][0]; i++) {
        labels.push(i)
    }

    // Mois acctuel
    const month = monthData[time()[1] - 1]

    return ( state === 0 || notify === 0 || data === 0? (<div className='loading'>
    <div className="db-spinner"></div>
</div>) : (<div id='dashboard'>
            <div className='dashboard__cards'>
                <PrimaryCard 
                    title={"Loyer"} 
                    amount={state["card"][0]['amount']}
                    percentage={state["card"][0]['percentage']}
                    pie={true}
                />
                <PrimaryCard 
                    title={"Pénalité"} 
                    amount={state["card"][1]['amount']}
                    percentage={state["card"][1]['percentage']}
                    pie={true}
                />
                <PrimaryCard 
                    title={"Frais divers"} 
                    amount={state["card"][2]['amount']}
                    percentage={state["card"][2]['percentage']}
                    pie={true}
                />
                <PrimaryCard 
                    title={"Prépayer"} 
                    amount={state["card"][3]['amount']}
                    percentage={state["card"][3]['percentage']}
                    pie={false}
                />
            </div>
            <div className='dashboard__body'>
                <Chart 
                    labels={labels}
                    data={data}
                    month={month}
                />
                <Notification dataNotify={notify}/>
            </div>
            <div className='dashboard__pie'>
                <Repartition 
                    name="Nombre de locataire" 
                    percentage={state['locataires'][1]}
                    number={state['locataires'][0]}
                />
                <Repartition 
                    name="chambres"
                    percentage={state['chambres'][1]}
                    number={state['chambres'][0]}
                />
            </div>
        </div> )
    )
}

export default Dashboard

/*
 * nbre, [
            nbre * 100 / (nbre + frais + penalite),
            frais *100 / (nbre + frais + penalite),
            penalite *100 / (nbre + frais + penalite)
        ]
 */