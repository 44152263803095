/**
 * LISTE DE PLAINTES :  en f(lue = ['LU', 'NON_LU'])
 */
import React from 'react'
import "./plaintesList.css"

import {useNavigate} from "react-router-dom"
import { useState, useEffect } from 'react'

import PlainteCard from '../../components/plainteCard/PlainteCard'

import { getPlainte } from '../../services/plainteService'


// {
//     "id": 1,
//     "titre": "string",
//     "description": "string",
//     "date_envoi": "2023-03-25",
//     "status": "NON_LU",
//     "message_reponse": "string",
//     "location": 2
// }


const PlaintesList = ({lue = "NON_LU"}) => {

    const [loading, setLoading] = useState(true)
    const [state , setState] = useState(0)

    useEffect(() => {
        setLoading(true)
        getPlainte(2)
        .then(
            res => {
                let list = []
                for(let i = 0; i < res.length; i++) {
                    if(lue == res[i]['status']) {
                        list.push(res[i])
                    }
                }                
                setState(list)
            }
        )
        setLoading(false)
    }, [lue, loading])

    const navigate = useNavigate()

    const NavigateClick = (event) => {
        navigate('/plaintes/lues')
    }

    return state === 0 || loading === true ? (
        <div className='loading'>
            <div className="db-spinner"></div>
        </div>) :(
        <div id='plaintesList'>
            { lue === "NON_LU" && <div className='locataires__header'>
                    <div></div>
                    <div className='locataires__ajout' onClick={NavigateClick}>
                        <span>Voir les plaintes lues</span>
                    </div>
                </div>}
            <div className='plaintesList__wrapper'>
                {lue === "LU" && <div className='multiStepForm__header'>
                    <div className='multiStepForm__title'>Plaintes lues</div>
                </div>}
                <div className='plaintesList__body'>
                    {state !== 0 ? state.map((data, index) => {
                        return  <PlainteCard
                                    loading={setLoading}
                                    title={data.titre}
                                    user={data.user}
                                    content={data.description}
                                    lue={data.status}
                                    date={data.date_envoi}
                                    location={data.location}
                                    id={data.id}
                                    key={index}
                                />
                    }) : ""}
                </div>
            </div>
        </div>
    )
}

export default PlaintesList