/**
 * NAVBAR
 */
import React from 'react'
import { useState } from 'react';
import { NavLink, Link } from 'react-router-dom'

import "./navbar.css"

import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import MoodBadOutlinedIcon from '@mui/icons-material/MoodBadOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Brightness4OutlinedIcon from '@mui/icons-material/Brightness4Outlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import { API } from '../../utils/const'

import logo from "../../assets/logo.svg"
import logoDark from "../../assets/logo-dark.svg"
import adminProfile from "../../assets/admin-profile.png"

import ThemeContext from '../../utils/themeContext';
import {logout} from "../../services/logoutService"
import { defaultIm } from '../../utils/nullImg';

const Navbar = ({file, nom, notify}) => {

    const [click, setClick] = useState(true)

    return (
        <ThemeContext.Consumer> 
            {({theme, toggleTheme}) => (
                <div id="navbar">
                    <div id="navbar__large">
                        <div className='navbar__desc'>
                            <div className='navbar__hello'>
                                <div className='navbar__title'>
                                    Salut {nom} !
                                </div>
                                <div className='navbar__subtitle'>
                                    Espérant que vous allez bien
                                </div>
                            </div>
                            <div className='navbar__option'>
                                <div className='navbar__notification'>
                                    {/**
                                     * Notification s'il y a de plaintes non lues
                                     */}
                                    <Link to="/plaintes">
                                        <NotificationsNoneOutlinedIcon sx={{fontSize: 30}}/>
                                        {notify === true && <div className='navbar--notify'></div>}
                                    </Link>
                                </div>
                                <div className='navbar__profil'>
                                    {/**
                                     * Theme toggle
                                     */}
                                    <div onClick={
                                        (e) => {
                                            e.preventDefault()
                                            toggleTheme()
                                        }
                                    }>
                                        <Brightness4OutlinedIcon sx={{fontSize: 30}}/>
                                    </div>
                                    <Link to="/profil">
                                        {/**
                                         * Profil : image
                                         */}
                                        <img 
                                        style={{borderRadius: "50%"}}
                                            src={file === "" || file === defaultIm ? adminProfile : file}
                                            alt="l'utilisateur"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="navbar__small">
                        {/**
                         * Navbar en mode smail : responsive
                         */}
                        <div className='navbar__menu' onClick={() => {
                            setClick(!click)
                        }}>
                            {click ? <MenuOutlinedIcon sx={{fontSize: 40}} className='blue' /> : <CloseOutlinedIcon sx={{fontSize: 40}} className='blue'/> }
                        </div>
                        <div className='navbar__logo'>
                            <Link to="/dashboard">
                                <img 
                                    src={theme === "dark" ? logo : logoDark}
                                    alt='logo: erent'
                                />
                            </Link>
                        </div>
                        <div className='navbar__notification'>
                            <Link to="/plaintes">
                                <NotificationsNoneOutlinedIcon sx={{fontSize: 30}}/>
                                {notify === true && <div className='navbar--notify'></div>}
                            </Link>
                        </div>
                    </div>
                    <div id={click ? "navbar--small" : "navbar--small-flex"}>
                        {/**
                         * Navbar en mode smail : responsive
                         */}
                        <div id='navbar--small__wrapper'>
                            <div className='navbar--small__list'>
                                <div className='navbar--small__list-wrapper'>
                                    <NavLink to="/dashboard">
                                        <div className='navbar--small__element'>
                                            <GridViewOutlinedIcon />
                                            <span>Dashboard</span>
                                        </div>
                                    </NavLink>
                                    <NavLink to="/locataires">
                                        <div className='navbar--small__element '>
                                            <PeopleAltOutlinedIcon />
                                            <span>Locataires</span>
                                        </div>
                                    </NavLink>
                                    <NavLink to="/residences">
                                        <div className='navbar--small__element'>
                                            <LocationCityOutlinedIcon />
                                            <span>Résidences</span>
                                        </div>
                                    </NavLink>
                                    <NavLink to="/plaintes">
                                        <div className='navbar--small__element'>
                                            <MoodBadOutlinedIcon />
                                            <span>Plaintes</span>
                                        </div>
                                    </NavLink>
                                    <NavLink to="/reçu">
                                        <div className='navbar--small__element'>
                                            <ReceiptLongOutlinedIcon />
                                            <span>Reçus</span>
                                        </div>
                                    </NavLink>
                                    <NavLink to="/historique">
                                        <div className='navbar--small__element'>
                                            <InsertChartOutlinedIcon />
                                            <span>Historiques</span>
                                        </div>
                                    </NavLink>
                                    <NavLink to="/parametre">
                                        <div className='navbar--small__element'>
                                            <SettingsOutlinedIcon />
                                            <span>Parametres</span>
                                        </div>
                                    </NavLink>
                                    <NavLink to="/profil">
                                        <div className='navbar--small__element '>
                                            <AccountCircleOutlinedIcon />
                                            <span>Profil</span>
                                        </div>
                                    </NavLink>
                                    <div className='navbar--small__element__theme' onClick={
                                        (e) => {
                                            e.preventDefault()
                                            toggleTheme()
                                        }
                                    }>
                                        <Brightness4OutlinedIcon sx={{fontSize: 30}}/>
                                    </div>
                                    <NavLink to="/login">
                                        <div className='sidebar__element ' onClick={() => {
                                            logout()
                                        }}>
                                            <LogoutOutlinedIcon />
                                            <span>Déconnecter</span>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </ThemeContext.Consumer>
    )
}

export default Navbar