/**
 *  LISTE DES CHAMBRES
 *  MODIFICATION CHAMBRES
 *  MODIFICATION DE JOUR DE PENALITE
 *  MODIFICATION DE RESIDENCE
 *  -> AJOUT DE CHAMBRE
 */
import React from 'react'
import './chambreList.css'
import { useEffect, useState } from 'react';

import {Link, useParams} from 'react-router-dom'
import DataTable from 'react-data-table-component';
import Modal from 'react-modal';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ThemeContext from '../../utils/themeContext';
import {getChambre, getInfos, updateResidence} from "../../services/residenceService"

import { getCategory , updateChambre,getOneChambre} from "../../services/residenceService"

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ChambreList = () => {

  /*
  {
    "id": 1,
    "designation": "string",
    "description": "string",
    "prix_moyen": "90000.00",
    "autres": "string",
    "etat_chambre": "string",
    "statut": "LIBRE",
    "residence": 1,
    "category": 1
  }
  */

  Modal.setAppElement(document.getElementById('root'));

    const params = useParams() // utilisation de params.nom pour la requete
    const {id} = params

    const [residence, setResidence] = useState([])
    const [list, setList] = useState(0)
    const [nom, setNom] = useState("")
    const [image, setImage] = useState()
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1)
    const [jour_penalite, setJour_penalite] = useState(0)
    const [modalIsOpen, setIsOpen] = useState(false)
    const [category, setCategory] = useState([])
    const [chambre, setChambre] = useState(0)

    useEffect(() => {
      getChambre(id)
      .then((res) => {
        setList(res)
        // console.log(res, id)
      })

      getInfos(id)
      .then((res) => {
        // GET RESIDENCE INFO
        setNom(res["nom_residence"])
        setJour_penalite(res["jour_penalite"])
        setResidence(res)
        setImage(res.image)
      })

      getCategory()
        .then(res => setCategory(res))        
    }, [id, step, modalIsOpen])

    const columns = [
        {
            name: 'id',
            selector: row => row.id,
            sortable: true,
        },
        {
          name: 'Désignation',
          selector: row => row.designation,
        },  
        {
            name: 'Prix',
            selector: row => row.prix_moyen,
            sortable: true,
        },
        {
          name: 'Status',
          selector: (row) => {
            if(row.statut === "LIBRE") {
              return <span className='green'>{row.statut}</span>
            } else {
              return <span className='red'>{row.statut}</span>
            }
          },
        },
        {
          name: "Modifer",
          selector: (row) => (<button 
            className='multiStepForm__but'
            onClick={async e => {
              setChambre(0)
              await getOneChambre(row.id)
                      .then(res => setChambre(res))
              setIsOpen(true)
            }}
          ><EditIcon /></button>),
        }
    ];

    const handleSubmit = async (event) => {
      event.preventDefault();
      const data = residence
      data["jour_penalite"] = jour_penalite
      setLoading(true)
      await updateResidence(data)
      setLoading(false)
    }

    const handleUpdate = async (event) => {
      event.preventDefault();
      setLoading(true)
      const response = await updateResidence(residence)
      setLoading(false)
      if(response !== "error") {
          setStep(1)
      }
    }

    const handleChangeJour = (event) => {
      setJour_penalite(event.target.value)
    }

    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      if (name === "image") {
        setResidence({...residence, [name] : event.target.files[0]});
        setImage(URL.createObjectURL(event.target.files[0]))
      } else {
        setResidence({...residence, [name] : value});
      }
    }

    const handleChangeChambre = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setChambre({...chambre, [name] : value});
  }

  const closeModal = () => {
      setIsOpen(false)
  }

  const handleSubmitChambre = async (event) => {
    event.preventDefault();
    setLoading(true)
    const response = await updateChambre(chambre)
    setLoading(false)
    if(response !== "error") {
      closeModal()
    }
  }

  // Modification de résidence
    const form = (
      <form onSubmit={handleUpdate}>
          <div id="residenceForm">
              <div className='residenceForm__wrapper'>
                  <div className='multiStepForm__header'>
                      <div className='multiStepForm__title'>Modification de résidence</div>
                  </div>
                  <div className='residenceForm__body'>
                      <div className='residenceForm__body__wrapper'>
                          <div className='residenceForm__form__flex'>
                              <div className='residenceForm__form__box'>
                                  <label>Nom</label>
                                  <input 
                                      type='text'
                                      name='nom_residence'
                                      placeholder='La gloire'
                                      onChange={handleChange}
                                      value={residence.nom_residence}
                                  />
                              </div>
                              <div className='residenceForm__form__box'>
                                  <label>Quatier</label>
                                  <input 
                                      type='text'
                                      name='quartier'
                                      placeholder='Zogbadjé'
                                      onChange={handleChange}
                                      value={residence.quartier}
                                  />
                              </div>
                              <div className='residenceForm__form__box'>
                                  <label>Arrondissement</label>
                                  <input 
                                      type='text'
                                      name='arrondissement'
                                      placeholder='Godomey'
                                      onChange={handleChange}
                                      value={residence.arrondissement}                                  />
                              </div>
                          </div>
                          <div className='residenceForm__form__flex'>
                              <div className='residenceForm__form__box'>
                                  <label>Ville</label>
                                  <input 
                                      type='text'
                                      name='ville'
                                      placeholder='Abomey-calavi'
                                      onChange={handleChange}
                                      value={residence.ville}
                                  />
                              </div>
                              <div className='residenceForm__form__box'>
                                  <label>Département</label>
                                  <input 
                                      type='text'
                                      name='departement'
                                      placeholder='Atlantique'
                                      onChange={handleChange}
                                      value={residence.departement}
                                  />
                              </div>
                              <div className='residenceForm__form__box'>
                                  <label>Pays</label>
                                  <input 
                                      type='text'
                                      name='pays'
                                      placeholder='Bénin'
                                      onChange={handleChange}
                                      value={residence.pays}
                                  />
                              </div>
                          </div>
                          <div className='residenceForm__form__flex'>
                              <div className='residenceForm__form__box__second'>
                                  <label>Description</label>
                                  <textarea 
                                      type='text'
                                      name='description'
                                      onChange={handleChange}
                                      value={residence.description}
                                  >
                                  </textarea>
                              </div>
                              <div className='form__box__second'>
                                  <div className='residenceForm__form__flex__second'>
                                      <div className='residenceForm__form__box__second'>
                                          <label>Adresse</label>
                                          <input 
                                              id='adresse'
                                              type='text'
                                              name='adresse'
                                              placeholder='Cotonou, Rue 450'
                                              onChange={handleChange}
                                              value={residence.adresse}
                                          />
                                      </div>
                                      <div className='form__box__file'>
                                          <label>Photo</label>
                                          <label className='form__file'>
                                              <div className='form__file__but' style={{boxShadow: "none"}}>
                                                  <img 
                                                    src={image}
                                                    alt='icon'
                                                  />
                                              </div>
                                          </label>
                                          <input 
                                              type='file'
                                              id='photo'
                                              name='image'
                                              accept=".jpg, .jpeg, .png"
                                              onChange={handleChange}
                                          />
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className='locataires__header'>
                      <div className='multiStepForm__but' onClick={e => setStep(1)}>
                        <ArrowBackIcon /><span>Retour</span>
                      </div>
                      <div className='residenceForm__footer'>
                        <button className='multiStepForm__but' type='submit'>
                            <span>Modifier</span>
                        </button>
                    </div>
                  </div>
              </div>
          </div>
      </form>
  )

  // Liste des chambre
    const comp = (
      <ThemeContext.Consumer>
          {({theme, _}) => (
            <div>
              <div id='locataires'>
                <div className='locataires__header'>
                  <div 
                    className='locataires__ajout'
                    onClick={e => setStep(2)}
                  >
                    <EditIcon /><span>Modifier la résidence</span>
                  </div>
                  <Link to={`/residences/${id}/chambre`}>
                    <div className='locataires__ajout'>
                      <AddOutlinedIcon /><span>Ajouter une chambre</span>
                    </div>
                  </Link>
                </div>
                <div className='locataires__header'>
                  <div className='locataires__title'>
                      Liste des chambre : <span>{nom}</span>
                  </div>
                </div>
                <div className='locataires__datatable'>
                  <div className='locataires__datatable__wrapper'>
                    <DataTable
                        columns={columns}
                        data={list}
                        pagination
                        theme={ theme === "dark" ? "default" : "dark"}
                    />
                  </div>
                </div>
                <div className='multiStepForm__header'>
                  <div className='multiStepForm__title'>Modification</div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='dashboard__title'>Modifier la date de payement de <b>{nom}</b> pour l'application des pénalités</div>
                  <div className='chambre__body__form__flex'>
                      <div className='profil__body__form__box'>
                          <input 
                              type='number'
                              name='nom'
                              min="1"
                              max="28"
                              value={jour_penalite}
                              onChange={handleChangeJour}
                          />
                      </div>
                      <button type='submit' className='but_mod'>
                          {loading === true ? <div className='loading' style={{marginTop: "-5px"}}>
                                        <div className="db-spinner"></div>
                                    </div> : 'Modifier'
                          }
                      </button>
                  </div>
                </form>
              </div>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                {/**
                 * Modification des chambres
                 */}
                {chambre === 0  ? <div className='loading'>
                          <div className="db-spinner"></div>
                      </div> : <div id="residenceForm">
                        <form className='residenceForm__wrapper' onSubmit={handleSubmitChambre}>
                            <div className='multiStepForm__header'>
                                <div className='multiStepForm__title'>Modification de chambre</div>
                            </div>
                            <div className='residenceForm__body'>
                                <div className='residenceForm__body__wrapper'>
                                    <div className='residenceForm__form__flex'>
                                        <div className='residenceForm__form__box'>
                                            <label>Désignation </label>
                                            <input 
                                                type='text'
                                                name='designation'
                                                placeholder='La gloire'
                                                onChange={handleChangeChambre}
                                                value={chambre.designation}
                                                required
                                            />
                                        </div>
                                        <div className='residenceForm__form__box'>
                                            <label>Categorie</label>
                                            <div className='select'>
                                                <select 
                                                  onChange={handleChangeChambre}
                                                  name="category"
                                                  defaultValue={chambre.category}
                                                >
                                                    {category.map((data, i) => <option value={data.id} key={i}>{data.denomination}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='residenceForm__form__box'>
                                            <label>Prix</label>
                                            <input 
                                                type='number'
                                                name='prix_moyen'
                                                value={chambre.prix_moyen}
                                                onChange={handleChangeChambre}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className='residenceForm__form__flex'>
                                        <div className='residenceForm__form__box__second'>
                                            <label>Description</label>
                                            <textarea 
                                                type='text'
                                                name='description'
                                                value={chambre.description}
                                                onChange={handleChangeChambre}
                                                required
                                            >
                                            </textarea>
                                        </div>
                                        <div className='form__box__second'>
                                            <div className='residenceForm__form__flex__second'>
                                                <div className='residenceForm__form__box'>
                                                    <label>Etat</label>
                                                    <div className='select'>
                                                        <select 
                                                          onChange={handleChangeChambre}
                                                          name="etat_chambre"
                                                          defaultValue={chambre.etat_chambre}
                                                        >
                                                            <option value="mauvais">mauvais</option>
                                                            <option value="bon">bon</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='residenceForm__form__box__second'>
                                                    <label>Autre information</label>
                                                    <input 
                                                        type='text'
                                                        name='autres'
                                                        value={chambre.autres}
                                                        onChange={handleChangeChambre}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='residenceForm__footer'>
                                <button className='multiStepForm__but' type='submit'>
                                    <span>Modifer</span>
                                </button>
                            </div>
                        </form>
                    </div>
                }
              </Modal>
            </div>
          )}
      </ThemeContext.Consumer>
    )

    return list === 0 ? <div className='loading'>
                          <div className="db-spinner"></div>
                      </div> : step === 1 ? comp : (loading === true) ? <div className='loading'>
                                    <div className="db-spinner"></div>
                                </div> : form
                      
}

export default ChambreList