/**
 * SIDEBAR
 */
import React from 'react'
import { NavLink, Link } from 'react-router-dom'

import "./sidebar.css"

import logo from "../../assets/logo.svg"
import logoDark from "../../assets/logo-dark.svg"

import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import MoodBadOutlinedIcon from '@mui/icons-material/MoodBadOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import ThemeContext from '../../utils/themeContext';
import {logout} from "../../services/logoutService"

const Sidebar = () => {

    return (
        <ThemeContext.Consumer>
            {({theme, _}) => (
                <div id='sidebar'>
                    <div id='sidebar__wrapper'>
                        <div className='sidebar__logo'>
                            <Link to="/dashboard">
                                <img 
                                    src={theme === "dark" ? logo : logoDark}
                                    alt='logo: erent'
                                />
                            </Link>
                        </div>
                        <div className='sidebar__list'>
                            <div className='sidebar__list-wrapper'>
                                <NavLink to="/dashboard">
                                    <div className='sidebar__element'>
                                        <GridViewOutlinedIcon />
                                        <span>Dashboard</span>
                                    </div>
                                </NavLink>
                                <NavLink to="/locataires">
                                    <div className='sidebar__element'>
                                        <PeopleAltOutlinedIcon />
                                        <span>Locataires</span>
                                    </div>
                                </NavLink>
                                <NavLink to="/residences">
                                    <div className='sidebar__element'>
                                        <LocationCityOutlinedIcon />
                                        <span>Résidences</span>
                                    </div>
                                </NavLink>
                                <NavLink to="/plaintes">
                                    <div className='sidebar__element'>
                                        <MoodBadOutlinedIcon />
                                        <span>Plaintes</span>
                                    </div>
                                </NavLink>
                                <NavLink to="/reçu">
                                    <div className='sidebar__element'>
                                        <ReceiptLongOutlinedIcon />
                                        <span>Reçus</span>
                                    </div>
                                </NavLink>
                                <NavLink to="historique">
                                    <div className='sidebar__element'>
                                        <InsertChartOutlinedIcon />
                                        <span>Historiques</span>
                                    </div>
                                </NavLink>
                                <NavLink to="/parametre">
                                    <div className='sidebar__element'>
                                        <SettingsOutlinedIcon />
                                        <span>Parametres</span>
                                    </div>
                                </NavLink>
                                <NavLink to="/profil">
                                    <div className='sidebar__element '>
                                        <AccountCircleOutlinedIcon />
                                        <span>Profil</span>
                                    </div>
                                </NavLink>
                                <NavLink to="/login">
                                    <div className='sidebar__element ' onClick={() => {
                                        logout()
                                    }}>
                                        <LogoutOutlinedIcon />
                                        <span>Déconnecter</span>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </ThemeContext.Consumer>

    )
}

export default Sidebar