/**
 * CARD POUR L'AFFICHAGE DES PLAINTES
 */
import React from 'react'
import "./plainteCard.css"

import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'

import { putStatus } from '../../services/plainteService'

import { defaultIm } from '../../utils/nullImg'
import adminProfile from "../../assets/admin.png"
import { API } from '../../utils/const'

// {
//     "titre": "string",
//     "description": "string",
//     "status": "NON_LU",
//     "message_reponse": "string",
//     "location": 0
// }

const PlainteCard = (props) => {

    // console.log(props)

    const handleClick = async (event) => {
        const data = {
            'id': props.id,
            "titre": props.title,
            "description": props.content,
            "status": "LU",
            "message_reponse": "Plainte lue. Bien reçu !!!",
            "location": props.location
        }

        await putStatus(data)

        props.loading(true)
    }

    return props !== 0 && <div>
        <div className='plainteCard__sub__title'>{props.user.nom + " " + props.user.prenom }</div>
        <div className='plainteCard__sub'>{props.date}</div>
        <div id="plainteCard">
            <div className='plainteCard__wrapper'>
                <div className='plainteCard__img'>
                    <img    
                        src={props.user.picture === `${API}/media/default.png` ? adminProfile : props.user.picture}
                        alt="locataire"
                    />
                </div>
                <div className='plainteCard__body'>
                    <div className='plainteCard__title'>{props.title}</div>
                    <div className='plainteCard__content'>{props.content}</div>
                </div>
                {
                    props.lue === "NON_LU" && <div className='lue' onClick={handleClick}>
                        <BookmarkBorderOutlinedIcon sx={{fontSize: 35}}/>
                    </div>
                }    
            </div>
        </div>
    </div>
}

export default PlainteCard