import API from "./Api"
import {getToken} from "./loginService"

/*

{
  "id": 5,
  "email": "abc@gmail.com",
  "nom": "string",
  "prenom": "string",
  "type": "REPRESENTANT",
  "telephone": "",
  "picture_url": "/media/default.png"
}

*/

export const getUser = async () => {
    const idToken = getToken()

    const inter = await API.get('/auth/profile/', {
                            headers : {
                                "Authorization" : 'Bearer ' + idToken
                            }
                        })
                        .then(res => {
                            /*
                            
                            {
                                "id": 5,
                                "email": "a@gmail.com",
                                "nom": "string",
                                "prenom": "string",
                                "type": "LOCATAIRE",
                                "telephone": "string",
                                "picture_url": "/media/default.png",
                                "adresse": "string",
                                "profession": "string",
                                "sexe": "HOMME",
                                "birth_date": "2023-05-18",
                                "description": "string"
                            }

                            {
                                "errors": {
                                    "detail": "Given token not valid for any token type",
                                    "code": "token_not_valid",
                                    "messages": [
                                        {
                                            "token_class": "AccessToken",
                                            "token_type": "access",
                                            "message": "Token is invalid or expired"
                                        }
                                    ]
                                }
                            }
                            
                            */
                            
                            if(res.data['errors'] !== "undefined") {
                                return res.data
                            } else {
                                return "error"
                            }
                        })
                        .catch(_ => "error")

    if(inter === "error") {
        return "error"
    }


    return inter
}

export const updatePicture = async (credentials) => {
    const idToken = getToken()
    const inter = await API.post('/auth/update_picture/' ,
                                {"picture": credentials} , 
                                {
                                    headers: {
                                        'content-type' : 'multipart/form-data',
                                        "Authorization" : 'Bearer ' + idToken,
                                        'Accept': 'application/json'
                                    }
                                })
                        .then(res => res.data)
                        .catch(_ => "error")
    
    if(inter === "error") {
        return []
    }

    return inter
}

export const updateUser = async (credentials) => {
    const idToken = getToken()
    const inter = await API.post('/auth/update/' , credentials , {
                                    headers: {
                                        "Authorization" : 'Bearer ' + idToken,
                                        'Accept': 'application/json'
                                    }
                                })
                        .then(res => res.data)
                        .catch(_ => "error")
    
    if(inter === "error") {
        return []
    }

    return inter
}

export const getUserId = async () => {
    const idToken = getToken()
    const inter = await API.get(`/auth/profile/`,{
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                            .then(res => res.data)
                            .catch(_ => "error")
    
    if(inter === "error") {
        return []
    }
    
    return inter['id']
}
export const getAdminId = async () => {
    const idToken = getToken()
    const inter = await API.get(`/auth/profile_representant`,{
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                            .then(res => res.data)
                            .catch(_ => "error")
    
    if(inter === "error") {
        return []
    }
    
    return inter['id']
}