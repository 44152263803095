/**
 * Detail des locataires
 *  |- infos personnelles
 *  |- infos de location : suspendre, visualiser, modifier un contrat
 */
import React, {useEffect, useState} from 'react'
import "./locataireDetail.css"

import { Link, useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { Alert, AlertTitle } from '@mui/material';

import userIm from "../../assets/user.png"
import { defaultIm } from '../../utils/nullImg'
import Modal from 'react-modal';

import { getOneLocataire, getLocataireChambre, getLocataireContrat} from '../../services/locataireService'
import ThemeContext from '../../utils/themeContext';
import { updateContrat, suspendre } from '../../services/locationService';

import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import adminProfile from "../../assets/admin.png"
import { API } from '../../utils/const';

const LocataireDetail = () => {

    const id = useParams().id
    const [data, setData] = useState(0)
    const [user, setUser] = useState(0)
    const [paye, setPaye] = useState("")
    const [none, setNone] = useState(true)
    const [loadImg, setLoadImg] = useState(false)
    const [load, setLoad] = useState(true)
    const [piece, setPiece] = useState("")

    Modal.setAppElement(document.getElementById('root'));

    useEffect(() => {
        getOneLocataire(id)
        .then((res) => {
            getLocataireChambre(res['id'])
            .then((chambre) => {
                let response;
                response = res['user']
                setPiece(res.piece_identite)
                setUser(response)
            })
        })
        getLocataireContrat(id)
        .then(res => {
            setData(res[0])
            setPaye(res[1])
        })
    }, [id, load])

    const handleChangeFile = async (event, id) => {
        setLoadImg(true)
        const v = await event.target.files[0]
        await updateContrat(id, v)
                .then(res => {
                    setLoadImg(false)
                    setLoad(!load)
                })
        setLoadImg(false)
    }

    const columns = [
        {
            name: 'chambre',
            selector: row => row.chambre,
            sortable: true,
        }, 
        {
            name: 'Loyer',
            selector: row => row.prix,
            sortable: true,
        }, 
        {
            name: 'date de départ',
            selector: row => row.date_depart,
            sortable: true,
        }, 
        {
            name: 'date de fin',
            selector: row => row.date_fin,
        }, 
        {
            name: 'contrat',
            selector: row => <div style={{display: "flex"}}>
                                <a href={row.url} target='_blank' rel="noreferrer">
                                    <div className='locataireDetail__but__mdp' style={{marginRight: "30px"}}>
                                        <VisibilityOutlinedIcon />
                                    </div>
                                </a>
                            </div>
        },
        {
            name: 'modifier',
            selector: row => <div style={{display: "flex"}}>
                                {!loadImg && <div className='locataireDetail__but__mdp'>
                                        <label htmlFor='file'><EditIcon /></label>
                                        <input 
                                            type='file'
                                            id='file'
                                            style={{display: "none"}}
                                            onChange={(e) => handleChangeFile(e, row.id)}
                                        />
                                    </div>
                                }
                            </div>
        },
        {
            name: 'action',
            selector: row => <div className='locataireDetail__but__mdp' style={{background: "var(--red)"}} onClick={async () => {
                    const confirmed = window.confirm("Êtes vous sur ?");

                    if (confirmed) {
                        await suspendre(row.id)
                            .then(res => {
                                if(res !== "error") {
                                    setNone(false)
                                }
                            })
                    }
                }}>
                    Suspendre
                </div>
        },
    ];

    return ( user === 0 || data === 0 ? <div className='loading'>
                <div className="db-spinner"></div>
            </div> :
            <ThemeContext.Consumer>   
                {({theme, _}) => (
                    <div id='locataireDetail'>
                        <div className='locataireDetail__wrapper'>
                            <div className='locataireDetail__but locataireDetail__but__style'>
                                <Link to={`/reçu/${id}`}>
                                    <div className='locataireDetail__but__mdp'>
                                        Voir les reçus
                                    </div>
                                </Link>
                                <a href={piece} target='_blank' rel="noreferrer">
                                    <div className='locataireDetail__but__mdp'>
                                        Voir la piece
                                    </div>
                                </a>
                            </div>
                            <div className='locataireDetail__header'>
                                <div className='locataireDetail__header__wrapper'>
                                    <Alert 
                                        onClose={() => setNone(true)}
                                        severity="success"
                                        className={`alert ${none && 'alert-none'}`}
                                        style={{
                                            top: "120px"
                                        }}
                                    >
                                        <AlertTitle>Succes</AlertTitle>
                                        <strong>Location suspendu avec succés</strong>
                                    </Alert>
                                    <div className='locataireDetail__header__img'>
                                        <img 
                                            style={{borderRadius: "50%", width: "205px", height: "205px"}}
                                            src={user['picture'] == `${API}/media/default.png` ? adminProfile : user.picture}
                                            alt='locataire'
                                        />
                                    </div>
                                    <div className='locataireDetail__header__content'>
                                        <div className='locataireDetail__header__title'>{user.nom + " " + user.prenom}</div>
                                        <div className='locataireDetail__header__email'> {user.email} </div>
                                        <div className='locataireDetail__header__email'> +{user.telephone === null ? 'Non enrégistrer' : user.telephone} </div>
                                        <div className={`locataireDetail__header__email ${paye === "Soldé" ? "green" : "red"}`}> {paye} </div>
                                    </div>
                                    <div className='locataireDetail__header__illustration'>
                                        <img 
                                            src={userIm}
                                            alt='illustration'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='locataireDetail__body'>
                                <div className='locataireDetail__body__wrapper'>
                                    <div className=''>
                                        <div className='locataires__title'>
                                            Récapitulatif du locataire
                                        </div>
                                    </div>
                                    <div className='locataireDetail__body__wrapper__datatable'>
                                        <div id='locataires'>
                                            <div className='locataires__datatable'>
                                                <div className='locataires__datatable__wrapper'>
                                                    <DataTable
                                                        columns={columns}
                                                        data={data}
                                                        theme={ theme === "dark" ? "default" : "dark"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ThemeContext.Consumer>
    )
}

export default LocataireDetail
