/**
 * ENVOIE DE MAIL
 */
import React from 'react'
import { useState } from 'react'
import { Alert, AlertTitle } from '@mui/material';

import '../login/Login'
import { sendMail } from '../../services/loginService';

import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';

const SendMail = () => {

    const [none, setNone] = useState(true)
    const [state, setState] = useState({})
    const [loading, setLoading] = useState(false)

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setState({...state, [name] : value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        await sendMail(state)
                            .then((res) => {
                                if(res !== "error") {
                                    setNone(false)
                                }
                            })
        setLoading(false)
    }

    return (
        <div id='login'>
            <Alert onClose={() => setNone(true)} severity="info" className={`alert ${none && 'alert-none'}`}>
                <AlertTitle>Info</AlertTitle>
                <strong>Consulter votre mail!</strong> <br />
                Un mail vous a été envoyé
            </Alert>
            <div className='signin'>
                <div className='signin__wrapper'>
                    <div className='signin__head'>
                        <div className='introduction__desc forgot-center'>Changer le mot de passe</div>
                    </div>
                    <div className='signin__form'>
                        <form onSubmit={handleSubmit}>
                            <div className='signin__box'>
                                <div className='signin__icon'>
                                    <HttpsOutlinedIcon className='blue'/>
                                </div>
                                <input
                                    className='introduction__desc input-1'
                                    type="email"
                                    name='email'
                                    value={state.email || ""}
                                    onChange={handleChange}
                                    placeholder='Entrer votre mail'
                                    required
                                />
                            </div>
                            {
                                loading === true ? <div className='loading'>
                                                        <div className="db-spinner"></div>
                                                    </div> : <button className='signin__submit' type='submit'>
                                                                Reçevoir un mail
                                                            </button>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default SendMail