import API from "./Api"
import {getToken} from "./loginService"

import { getOneChambre } from "./residenceService"
import img from "../assets/admin.png"

import {time, getMonth, getYear} from "../utils/time"

let date = time()

// {
//     "id": 1,
//     "is_active": true,
//     "piece_identite": null,
//     "status": "NOUVEAU",
//     "photo": "http://3.142.172.6:8000/media/default.png",
//     "user": {
//       "id": 1,
//       "password": "pbkdf2_sha256$390000$rqsLljZVqyzRIkUMSHrVb0$Lo2AYeeBRrOtFErOU3BN2df4QlUOUBf8aNIGoatEYiM=",
//       "last_login": null,
//       "is_superuser": false,
//       "email": "hernandezdecos96@gmail.com",
//       "nom": "DOE",
//       "prenom": "John",
//       "sexe": "NON_DEFINI",
//       "type": "LOCATAIRE",
//       "picture": "http://3.142.172.6:8000/media/default.png",
//       "telephone": "",
//       "birth_date": "2023-03-27",
//       "adress": "",
//       "is_admin": false,
//       "date_created_at": "2023-03-27T18:08:52.652399Z",
//       "date_updated_at": "2023-03-27T18:08:52.652429Z",
//       "groups": [],
//       "user_permissions": []
//     }
// }

// Proprietiaire service

export const getLocataire = async () => {
    const idToken = getToken()
    const inter = await API.get(`/location/LocataireAdminManage/`,{
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    return inter
}

export const getAdminLocataire = async () => {
    const idToken = getToken()
    const inter = await API.get(`/location/LocataireAdminManage/by_filter_by_proprietaire_connected/`,{
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    return inter
}

export const getOneLocataire = async (id) => {
    const idToken = getToken()

    const inter = await API.get(`/location/LocataireAdminManage/${id}/`,{
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")
    
    if(inter === "error") {
        return []
    }

    return inter
}

export const updateLocataire = async (data) => {

    /*
    
    {
        "email": "user@example.com",
        "nom": "string",
        "prenom": "string",
        "type": "LOCATAIRE",
        "telephone": "string",
        "adresse": "string",
        "profession": "string",
        "sexe": "HOMME",
        "birth_date": "2023-05-20",
        "description": "string"
    }
    
    */

    const id = data.id
    const idUp = data.idUp

    const idToken = getToken()
    const inter = await API.put(`/location/UserAdminManage/${id}/`,{
                                "email": data["email"],
                                "nom": data["nom"],
                                "prenom": data["prenom"],
                                "type": "LOCATAIRE",
                                "telephone": data["telephone"],
                                "adresse": data["adresse"],
                                "profession": data["profession"],
                                "sexe": data["sexe"],
                                "birth_date": data["birth_date"],
                                "description": data["description"]
                            },{
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")
    
    if(inter === "error") {
        return []
    }

    const addPiece = await API.post(`/auth/add_piece_identity_by_admin`,
                            {
                                id : idUp,
                                piece_identite : data['piece']
                            },
                            {
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken,
                                    'content-type' : 'multipart/form-data',
                                    'Accept': 'application/json'
                                }
                            })
                        .then(res => res.data)
                        .catch(_ => "error")

    if(addPiece === "error") {
        return 
    }
}

export const getLocataireId = async (email) => {
    const idToken = getToken()
    const inter = await API.get(`/location/LocataireAdminManage/by_email/?email=${email}`,{
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                            .then(res =>{ 
                                // console.log(res)
                                return res.data
                            })
                            .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    // console.log(inter)
    return inter[0]['id']
}

export const getLocataireChambre = async (id) => {
    const idToken = getToken()
    // const id = await getLocataireId(email)

    const inter = await API.post(`/location/get_chambres_by_locataire`,
                            {
                                "locataire_id": id,
                                "statut": "EN_COURS"
                            },
                            {
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                            .then(res => res.data)
                            .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    /*
        {
            "chambres": [
                1
            ]
        }
    */

    /*

        Liste de chambre

    */

    let chambres = ""

    for(let i = 0; i < inter["chambres"].length; i++) {
        const chambre = await getOneChambre(inter["chambres"][i])
        if(chambre["statut"] === "OCCUPE") {
            chambres += " " + chambre["designation"]
        }
    }
    
    return chambres
}

export const getLocataireList = async (res) => {
    // Locataire de l'admin

    let list = []
    let idList = []
    let chambre = ""

    for(let i = 0; i < res.length; i++) {
        let actual = res[i]

        if(actual.is_active == true && !idList.includes(actual.id)) {

          chambre = await getLocataireChambre(actual.id)

          if(chambre === "") {
            break
          } 

          idList.push(actual.id)

          list.push({
            id: actual.id,
            status: actual.status,
            img: actual.user.picture == `${API}/media/default.png` ? img : actual.user.picture,
            title: actual.user.nom + " " + actual.user.prenom,
            year: actual.user.date_created_at.slice(0, 10),
            piece: actual.piece_identite
            // chambre: chambre
          })
        }
    }

    // console.log(list)

    return list
}

// const dataLocataire = [
//     {
//       "type": "loyer",
//       "du": 50000,
//       "paye": 50000,
//       "reste": 0
//     },
//     {
//       "type": "pénalité",
//       "du": 50000,
//       "paye": 50000,
//       "reste": 0
//     },
//     {
//       "type": "Frais divers",
//       "du": 50000,
//       "paye": 50000,
//       "reste": 0
//     },
// ]

export const getFraisDivers = async (id) => {
    const idToken = getToken()
    const inter = await API.get(`/location/frais_divers/`,{
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                            .then(res => res.data)
                            .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    /*
    
    [
        {
            "id": 1,
            "titre": "string",
            "description": "string",
            "date_envoi": "2023-04-13",
            "montant": "10000.00",
            "status": "NON_PAYE",
            "location": 1
        }
    ]

    */

    let response;

    for(let i = 0; i < inter.length; i++) {
        if(date[1] === getMonth(inter[i].date_envoi) && date[2] === getYear(inter[i].date_envoi)) {
            response = inter[i]
            break
        }
    }

    const montant = parseInt(response["montant"])

    return {
            "type": "loyer",
            "du": montant,
            "paye": response["status"] === "PAYE" ? montant : 0,
            "reste": response["status"] === "PAYE" ? 0 : montant
        }
}

export const getLocataireContrat = async (id) => {
    const idToken = getToken()

    const inter = await API.get(`/location/location_infos/by_all_without_contrainte/?locataire=${id}`,{
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                            .then(res => res.data)
                            .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    /*
    [
        {
            "id": 1,
            "date_depart": "2023-02-05",
            "date_fin": "2024-02-05",
            "status": "EN_COURS",
            "contrat": "http://3.142.172.6:8000/media/Chambre_Salon_Zogbadje_RDCCS1.pdf",
            "signature": null,
            "prix_loc": "45000.00",
            "mod_paiement": "MENSUEL",
            "prefs_paiement": "PREPAYE",
            "chambre": 1,
            "locataire": 1
        }
    ]
    */

    const nbre = inter.length

    const paiement = await API.get(`/location/paiement/${"?locataire="+id+"&type_paiement=LOYER"}`,
                        {
                            headers: {
                            'content-type': 'multipart/form-data',
                            }
                        })
                        .then(res => res.data)
                        .catch(_ => "error")

    let n = 0
    for(let i = 0; i < paiement.length; i++) {
        if( paiement[i].date.slice(0, 4) == date[2] && paiement[i].date.slice(5, 7) == date[1] ) {
            n += 1
        }
    }

    let answer = []
    for(let i = 0; i < inter.length; i++) {

        const chambre = await getOneChambre(inter[i]['chambre'])

        answer.push({
            "id": inter[i]['id'],
            "prix": inter[i]['prix_loc'],
            "date_depart": inter[i]['date_depart'],
            "date_fin": inter[i]['date_fin'],
            "chambre": chambre['designation'],
            "url": inter[i]['contrat'],
            "idContrat": inter[i]['idContrat'],
            "recu": inter[i]['recu']
        })
    }

    return [answer, n == nbre ? "Soldé" : "Non soldé"]
}

/*

[
  {
    "id": 177,
    "date_depart": "2023-04-21",
    "date_fin": "2023-05-21",
    "marge_paiement": "5 00:00:00",
    "montant": "3334.00",
    "status": "NON_PAYE",
    "location": 19
  },
  {
    "id": 178,
    "date_depart": "2023-05-21",
    "date_fin": "2023-06-21",
    "marge_paiement": "5 00:00:00",
    "montant": "3334.00",
    "status": "NON_PAYE",
    "location": 19
  },
  {
    "id": 179,
    "date_depart": "2023-06-21",
    "date_fin": "2023-07-14",
    "marge_paiement": "5 00:00:00",
    "montant": "3334.00",
    "status": "NON_PAYE",
    "location": 19
  }
]

*/

export const getLoyer = async (id) => {
    const idToken = getToken()

    const location = await API.get(`/location/location_infos/by_all_without_contrainte/?locataire=${id}`,{
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                            .then(res => res.data)
                            .catch(_ => "error")

    if(location === "error") {
        return []
    }

    const inter = await API.get(`/location/Loyer/?location=${id}`,{
                                headers : {
                                    "Authorization" : 'Bearer ' + idToken
                                }
                            })
                            .then(res => res.data)
                            .catch(_ => "error")

    if(inter === "error") {
        return []
    }

    /*
    
    [
        {
            "id": 1,
            "date_depart": "2023-02-01",
            "date_fin": "2023-03-01",
            "marge_paiement": "5 00:00:00",
            "montant": "80000.00",
            "status": "PAYE",
            "location": 1
        },
    ]

    */

    let response;

    for(let i = 0; i < inter.length; i++) {
        if(date[1] === getMonth(inter[i].date_depart) && date[2] === getYear(inter[i].date_depart)) {
            response = inter[i]
            break
        }
    }

    const montant = parseInt(response["montant"])

    return {
            "type": "loyer",
            "du": montant,
            "paye": response["status"] === "PAYE" ? montant : 0,
            "reste": response["status"] === "PAYE" ? 0 : montant
        }
}