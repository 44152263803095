/**
 * PROFIL
 *  |- Affichage de profil
 *  |- Modification de profil
 */
import React from 'react'
import "./profil.css"

import { useState, useEffect } from 'react'

import adminProfile from "../../assets/admin-profile.png"
import PhotoCameraBackOutlinedIcon from '@mui/icons-material/PhotoCameraBackOutlined';

import {updatePicture, getUser, updateUser} from "../../services/userService"
import { API } from '../../utils/const';
import { defaultIm } from '../../utils/nullImg';

const Profil = () => {

    const [click , setClick] = useState(false)
    const [file, setFile] = useState("")
    const [loadImg, setLoadImg] = useState(false)
    const [stateForm, setStateForm] = useState(0)
    const [loading, setLoading] = useState(false)
    const [load, setLoad] = useState(false)

    const  [state , setState] = useState(0)

    // {
    //     "email": "abc@gmail.com",
    //     "nom": "string",
    //     "prenom": "string",
    //     "telephone": "",
    //     "picture_url": "/media/default.png"
    // }

    useEffect(() => {
        getUser()
        .then(res => {
            setState(res)
            setFile(API + res.picture_url)
            setStateForm(res)
        })
    }, [file, load])

    const handleChangeFile = async (event) => {
        setLoadImg(true)
        const v = await event.target.files[0]
        const response = await updatePicture(v)
        if(response !== "error") {
            getUser().then(res => {
                setFile(API + res.picture_url)
            })
            setLoadImg(false)
        }
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setStateForm({...stateForm, [name] : value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        await updateUser(stateForm)
        setLoading(false)
        setLoad(!load)
    }

    const comp = (
        <div id='profil'>
            <div className='profil__wrapper'>
                <div className='profil__header'>
                    <div className='profil__header__tile'>
                        <div>{state.nom} {state.prenom}</div>
                    </div>
                    <div className='profil__header__img'>
                        <img
                            src={file === "" || file === defaultIm ? adminProfile : file}
                            alt='profil' 
                        />
                        <div className='form__file__but contrat__pos'>
                            <label htmlFor="contrat" className='label__contrat'>
                                <PhotoCameraBackOutlinedIcon />
                            </label>
                            <input 
                                disabled = {loadImg}
                                type='file'
                                id="contrat"
                                name='picture_url'
                                accept=".jpg, .jpeg, .png, .pdf"
                                onChange={handleChangeFile}
                            />
                        </div>
                    </div>
                    <div className='profil__header__other'>
                        <div>{state.email}</div>
                        <div>{state.telephone} </div>
                    </div>
                </div>
                <div className='profil__body'>
                    <div className='profil__body__title'>Informations personnelles</div>
                    <div className='profil__body__form'>
                        <form onSubmit={handleSubmit}>
                            <div className='profil__body__form__flex'>
                                <div className='profil__body__form__box'>
                                    <label>Nom</label>
                                    <input 
                                        disabled={!click}
                                        type='text'
                                        name='nom'
                                        value={stateForm.nom}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='profil__body__form__box'>
                                    <label>Téléphone</label>
                                    <input 
                                        disabled={!click}
                                        type='number'
                                        name='telephone'
                                        value={stateForm.telephone}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='profil__body__form__flex'>
                                <div className='profil__body__form__box'>
                                    <label>Prénom</label>
                                    <input 
                                        disabled={!click}
                                        type='text'
                                        name='prenom'
                                        value={stateForm.prenom}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='profil__body__form__box'>
                                    <label>Adresse</label>
                                    <input 
                                        disabled={!click}
                                        type='text'
                                        name='email'
                                        value={stateForm.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='profil__body__form__but'>
                                {click === false ? 
                                    <div className='profil__body__form__button' onClick={() => setClick(!click)}>Modifier</div> :
                                    <button className='profil__body__form__button' type='submit' onClick={(e) => {
                                        setClick(!click)
                                        handleSubmit(e)
                                    }}>Valider</button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )

    return stateForm === 0 || state === 0 || loading === true ? <div className='loading'>
                            <div className="db-spinner"></div>
                        </div> : comp
}

export default Profil