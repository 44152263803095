/**
 * FORMULAIRE D'ENREGISTREMENT DE CHAMBRE
 */

import React from 'react'
import './chambreForm.css'

import { useState, useEffect } from 'react'
import {useParams, useNavigate} from 'react-router-dom'

import { saveChambre, getCategory } from "../../services/residenceService"

const ChambreForm = () => {

    /*
      
    {
        "designation": "string9",
        "description": "string",
        "prix_moyen": "90000",
        "autres": "string",
        "etat_chambre": "string",
        "statut": "LIBRE",
        "residence": 3,
        "category": 1
    } 

    autres: "hihj"
    description: "hlj"
    designation: "jkj
    prix_moyen: "111"
    statut: "LIBRE"

    */

    const [state, setState] = useState({})
    const [list, setList] = useState(0)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const {id} = useParams()

    useEffect(() => {
        getCategory()
            .then(res => setList(res))
        setLoading(false)
    }, [])

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setState({...state, [name] : value});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        const response = await saveChambre(state, id)
        setLoading(false)
        if(response !== "error") {
            navigate(`/residences/${id}`);
        }
    }

    return (list === 0 || loading === true) ? <div className='loading'>
                          <div className="db-spinner"></div>
                      </div> : (
                                <div id="residenceForm">
                                    <form className='residenceForm__wrapper' onSubmit={handleSubmit}>
                                        <div className='multiStepForm__header'>
                                            <div className='multiStepForm__title'>Création  d'une chambre</div>
                                        </div>
                                        <div className='residenceForm__body'>
                                            <div className='residenceForm__body__wrapper'>
                                                <div className='residenceForm__form__flex'>
                                                    <div className='residenceForm__form__box'>
                                                        <label>Désignation </label>
                                                        <input 
                                                            type='text'
                                                            name='designation'
                                                            placeholder='La gloire'
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className='residenceForm__form__box'>
                                                        <label>Categorie</label>
                                                        <div className='select'>
                                                            <select onChange={handleChange} name="category" required>
                                                                <option value="">Choisir une option</option>
                                                                {list.map((data, i) => <option value={data.id} key={i}>{data.denomination}</option>)}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='residenceForm__form__box'>
                                                        <label>Prix</label>
                                                        <input 
                                                            type='number'
                                                            name='prix_moyen'
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className='residenceForm__form__flex'>
                                                    <div className='residenceForm__form__box__second'>
                                                        <label>Description</label>
                                                        <textarea 
                                                            type='text'
                                                            name='description'
                                                            onChange={handleChange}
                                                            required
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <div className='form__box__second'>
                                                        <div className='residenceForm__form__flex__second'>
                                                            <div className='residenceForm__form__box'>
                                                                <label>Etat</label>
                                                                <div className='select'>
                                                                    <select onChange={handleChange} name="etat_chambre" required>
                                                                        <option value="">Choisir une option</option>
                                                                        <option value="mauvais">mauvais</option>
                                                                        <option value="bon">bon</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='residenceForm__form__box__second'>
                                                                <label>Autre information</label>
                                                                <input 
                                                                    type='text'
                                                                    name='autres'
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='residenceForm__footer'>
                                            <button className='multiStepForm__but' type='submit'>
                                                <span>Soumettre</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            )

}

export default ChambreForm