/**
 * CARD POUR LES REPARTITION
 */
import React from 'react'
import "./repartition.css"

import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

const Repartition = ({name, percentage, number}) => {

    let title = ""

    let pieColor;
    let labels;
    if(percentage.length === 3) {
        title = "Répartition de frais"

        pieColor = [
            "rgba(79, 209, 139, 1)",
            "rgba(220, 53, 69, 1)",
            "rgba(157, 155, 244, 1)",
        ]

        labels = [
            "Loyer",
            "pénalité",
            "divers",
        ]

    } else if(percentage.length === 2) {
        title = "Aperçu des chambre"

        pieColor = [
            "rgba(79, 209, 139, 1)",
            'rgba(239, 244, 253, 1)'
        ]

        labels = [
            "Occupé",
            "Libre"
        ]
    }

    ChartJS.register(ArcElement, Tooltip, Legend);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'left',
                display: "false",
                labels: {
                    padding: 15,
                    font: {
                        size: 14
                    }
                }
            }
        }
    }

    const data = {
        labels,
        datasets: [
            {
                data: percentage,
                backgroundColor: pieColor,
                borderColor: pieColor,
                borderWidth: 1,
            },
        ],
    };

    return (
        <div id='repartition'>
            <div className='repartition-title'>
                <div>
                    {title}
                </div>
                <div className='pie__numbers'>
                    <div>{number}</div>
                    <div>{name}</div>
                </div>
            </div>
            <div className='pie__el'>
                <div className='pie__wrapper'>
                    <div className='pie__pie'>
                        <Doughnut data={data} options={options}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Repartition